<div
  fxLayout="column"
  fxLayoutGap="20px"
>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >Add new response set
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <app-response-panel [config]="config" [object]="object" [parent]="dialogRef"></app-response-panel>
  </mat-dialog-content>
</div>
