<div>
  <mat-card
    *ngIf="dataSource.data.length > 0 && (config.page === 'publish' || !config.massEdit)"
    class="detail" fxLayout="column" fxLayoutGap="0%"
  >
    <div
      class="scroll-table mat-elevation-z2"
    >
      <table
        mat-table
        [dataSource]="dataSource"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="w-10" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)"
                          [disabled]="disableRow(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="error">
          <th class="w-40" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell style="text-align: center" *matCellDef="let element">
            <mat-icon
              *ngIf="element.sta === 7"
              style="color: red"
              matTooltip="This store is suspended."
            >error</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="fullAccount">
          <th class="w-400" mat-header-cell *matHeaderCellDef>Account Name</th>
          <td mat-cell *matCellDef="let element">{{ element.nam }}</td>
        </ng-container>
        <ng-container matColumnDef="branchCode">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Account</th>
          <td mat-cell *matCellDef="let element">{{ element.chain }}</td>
        </ng-container>
        <ng-container matColumnDef="storeCode">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Store Nbr</th>
          <td mat-cell *matCellDef="let element">{{ element.str }}</td>
        </ng-container>
        <ng-container matColumnDef="ownRef">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Own Ref</th>
          <td mat-cell *matCellDef="let element">{{ element.strOwnRef }}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th class="w-400" mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let element">{{ element.stAdr }}</td>
        </ng-container>
        <ng-container matColumnDef="territory">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Territory</th>
          <td mat-cell *matCellDef="let element">{{ element.terr }}</td>
        </ng-container>
        <ng-container
          *ngIf="config.activityType == getStockCountActivityType()"
          matColumnDef="stockCountDate"
        >
          <th class="w-75" mat-header-cell *matHeaderCellDef>Stock Count Date</th>
          <td mat-cell *matCellDef="let element">{{ element.stockCountDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>
        <ng-container
          *ngIf="config.activityType == getShipperCountValue()"
          matColumnDef="shipperCount"
        >
          <th class="w-75" mat-header-cell *matHeaderCellDef>{{ getEnvironment.shipperName }}</th>
          <td mat-cell *matCellDef="let element">

            <mat-form-field>
              <input type="number" id="shprCtInput" #shprCtInput (input)="change(shprCtInput.value, element)" matInput required [(ngModel)]="element.shprCt" [ngModelOptions]="{standalone: true}"/>
              <mat-error
                *ngIf="element.shprCt?.toString().trim() === '' || element.shprCt == null || element.shprCt == undefined"
              >
                {{ getEnvironment.shipperName }} is mandatory.
              </mat-error>
            </mat-form-field>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"
          [ngStyle]="disableRow(element) && {'background-color': 'lightgrey'}"></tr>
      </table>
    </div>

    <mat-hint
      fxFlex
      fxLayout="row" fxLayoutAlign="end start"
    >
      <span
        style="margin-top: 10px"
      >
        Stores: {{ dataSource.data ? dataSource.data.length : 0 }}
      </span>
    </mat-hint>

  </mat-card>

  <div
    *ngIf="(config.page == 'publish' || config.page == 'manage-group') && !config.massEdit && !config.isPersonalActivity"
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayout.xs="column"
    fxLayoutAlign.xs="space-between start"
  >
    <button
      fxFlex="12%;"
      fxFlex.xs="46%"
      mat-button
      [disableRipple]="true"
      (click)="selectChainGroupForStoreFile()"
      class="activity-btn"
    >
      <mat-icon>attach_file</mat-icon>
      <span *ngIf="config.activityType != getStockCountActivityType()">Store File</span>
      <span *ngIf="config.activityType == getStockCountActivityType()">Stock Count File</span>
    </button>
    <input #fileInput type="file" accept=".xlsx,.txt" name="file" id="file"
             class="file-choose-input" (change)="onFileSelected($event)"/>

    <div fxFlex="1%;"
         fxFlex.xs="0">
    </div>

    <button
      fxFlex="12%;"
      fxFlex.xs="46%"
      mat-button
      [disableRipple]="true"
      (click)="selectChainGroupForLoadStore()"
      class="activity-btn"
    >
      <i class="material-icons i-material-icons">add_circle_outline</i>Add Store
    </button>

    <div fxFlex="1%;"
         fxFlex.xs="0">
    </div>

    <button
      *ngIf="config.activityType == getStockCountActivityType()"
      fxFlex="12%;"
      fxFlex.xs="46%"
      mat-button
      [disableRipple]="true"
      [disabled]="this.selection.selected.length != 1"
      (click)="editStockCountDate()"
      class="activity-btn"
    >
      <i class="material-icons i-material-icons">edit</i>Amend Stock Count Date
    </button>

    <div fxFlex="1%;"
         fxFlex.xs="0">
    </div>

    <button
      fxFlex="12%;"
      fxFlex.xs="46%"
      mat-button
      [disableRipple]="true"
      [disabled]="selection.selected.length <= 0"
      (click)="verifyIfRemoveIsPossible()"
      class="activity-btn-red"
    >
      <div class="material-icons div-material-icons">
        remove_circle_outline
      </div>
      <span *ngIf="this.selection.selected.length <= 1">Remove Store</span>
      <span *ngIf="this.selection.selected.length > 1">Remove Stores</span>
    </button>

    <div fxFlex="1%;"
         fxFlex.xs="0">
    </div>
  </div>

</div>
