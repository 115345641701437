<div
  class="wrapper"
>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    class="mat-elevation-z2"
  >
    <form
      fxLayout="column"
      fxLayoutGap="5px"
      [formGroup]="form"
    >
      <mat-form-field appearance="standard">
        <mat-label>Compliance Type</mat-label>
        <mat-select formControlName="taskType" required (selectionChange)="selectionChange($event)">
          <mat-option *ngFor="let activity of activityType" [value]="activity.actTypeId">
            <span *ngIf="activity.actTypeId !== getActivityType().SHIPPER_COUNT">{{ activity.en }}</span>
            <span *ngIf="activity.actTypeId === getActivityType().SHIPPER_COUNT">{{ getEnvironment.shipperName }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
      >
        <mat-form-field
          fxFlex="32%"
          fxFlex.lt-md="100%"
          appearance="standard"
          *ngIf="isComplianceOrShipperCount() || isPhysicalStockCount()"
        >
          <mat-label>Customer Account</mat-label>
          <mat-select formControlName="chainGroupId" [required]="!isPersonalActivity" (selectionChange)="onChainGroupChange()">
            <mat-option *ngFor="let group of filterChainGroups(chainGroups)" [value]="group.groupId"> {{ group.desc }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex="32%"
          fxFlex.lt-md="100%"
          appearance="standard"
          *ngIf="(isComplianceOrShipperCount() || isPhysicalStockCount()) && isLineOfSupplyValid()"
        >
          <mat-label>Customer Line of Supply</mat-label>
          <mat-select formControlName="lineOfSupply" [required]="!isPersonalActivity" [disabled]="!chainGroupSelected()">
            <mat-option *ngFor="let line of customerLineOfSupplyList" [value]="line"> {{ line }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label *ngIf="isCompliance()">CDS/Memo Activity Name</mat-label>
          <mat-label *ngIf="!isCompliance()">Activity Name</mat-label>
          <input
            matInput
            formControlName="activityOptionalName"
            [maxLength]="getActivityOptionalNameMaxLength()"
            required
            id="optionalName"
          />
          <mat-hint>{{ form.controls.activityOptionalName.value ? form.controls.activityOptionalName.value.length : 0 }}/{{
              getActivityOptionalNameMaxLength() }}</mat-hint>
          <button
            mat-button
            *ngIf="form.controls.activityOptionalName.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="form.controls.activityOptionalName.setValue('')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="form.controls.activityOptionalName.errors?.required && form.controls.activityOptionalName.dirty">
            Activity Name is Mandatory.
          </mat-error>
          <mat-error *ngIf="form.controls.activityOptionalName.errors?.pattern && form.controls.activityOptionalName.dirty">
            * Input not allowed
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
      >
        <mat-form-field
          fxFlex="100%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label>Response Set Activity Type</mat-label>
          <mat-select
            formControlName="respGroupType" required
            (selectionChange)="updateInstallationType()">
            <mat-option *ngFor="let type of responseGroupTypes" [value]="type.id"> {{ type.type }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label>Installation Activity Type</mat-label>
          <mat-select formControlName="installationType" required [disabled]="!respGrpTypeSelected()">
            <mat-option *ngFor="let installation of installationTypes" [value]="installation.id"> {{ installation.desc }} </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-form-field fxFlex appearance="standard">
        <mat-label>Select a default list of responses</mat-label>
        <mat-select formControlName="defaultSet">
          <mat-option *ngFor="let response of responses" (click)="sendDataToChild(response.responses)" [value]="response.responses">
            {{ response.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <app-load-responses
      #child
      style="margin-bottom: 10px"
      [config]="buildConfig()"
    ></app-load-responses>

    <form
      fxLayout="column"
      fxLayoutGap="10px"
      [formGroup]="form"
    >

      <div
        fxFlex
        class="form-group has-feedback mat-elevation-z0"
        [ngClass]="{ 'has-error': form.controls.desc.invalid && form.controls.desc.touched }"
      >
        <span class="description">Description</span>
        <ckeditor
          [config]="config"
          formControlName="desc"
          required
        ></ckeditor>
        <mat-error *ngIf="form.controls.desc.errors?.required && form.controls.desc.dirty">
          * Description is Mandatory.
        </mat-error>
        <mat-error *ngIf="form.controls.desc.errors?.pattern && form.controls.desc.dirty">
          * Input not allowed
        </mat-error>
        <mat-error *ngIf="form.controls.desc.errors?.maxLength && form.controls.desc.touched">
          {{form.controls.desc.errors?.maxLength}}
        </mat-error>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="2%"
        fxLayoutGap.lt-md="0"
        *ngIf="!isPhysicalStockCount()"
      >
        <mat-form-field
          fxFlex="32%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label *ngIf="!isComplianceOrShipperCount()">Choose Activity start date</mat-label>
          <mat-label *ngIf="isComplianceOrShipperCount()">Choose Installation start date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            id="picker1"
            value=""
            (click)="picker1.open()"
            (keydown)="(false)"
            formControlName="startDate"
            [required]="!isPhysicalStockCount()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1 class="picker"></mat-datepicker>
          <mat-error
            *ngIf="
                form.controls.startDate.touched &&
                form.controls.startDate.invalid
              "
          >
            Start date is Mandatory.
          </mat-error>
        </mat-form-field>

        <mat-form-field
          fxFlex="32%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label *ngIf="!isComplianceOrShipperCount()">Choose Activity end date</mat-label>
          <mat-label *ngIf="isComplianceOrShipperCount()">Choose Installation end date</mat-label>
          <input
            matInput
            formControlName="endDate"
            [matDatepicker]="picker2"
            id="picker2"
            value=""
            (click)="picker2.open()"
            (keydown)="(false)"
            [min]="form.controls.startDate.value"
            [required]="!isPhysicalStockCount()"
            (dateChange)="updateExpirationDate($event)"
          />
          <mat-datepicker-toggle
            id="picker2icon"
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error
            *ngIf="
                form.controls.endDate.touched &&
                form.controls.endDate.errors &&
                form.controls.endDate.errors.required
              "
          >
            End date is Mandatory.
          </mat-error>
          <mat-error
            *ngIf="
                form.controls.endDate.touched &&
                form.controls.endDate.errors &&
                form.controls.endDate.errors.endDateSmallerThanSysdate
              "
          >
            End date has to be after current date.
          </mat-error>
        </mat-form-field>

        <mat-form-field
          fxFlex="32%"
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label *ngIf="!isComplianceOrShipperCount()">Choose Activity expiration date</mat-label>
          <mat-label *ngIf="isComplianceOrShipperCount()">Choose Installation expiration date</mat-label>
          <input
            matInput
            formControlName="expirationDate"
            [matDatepicker]="picker3"
            id="picker3"
            value=""
            (click)="picker3.open()"
            (keydown)="(false)"
            [min]="form.controls.endDate.value"
            [required]="!isPhysicalStockCount()"
            [disabled]="!isMerchAdmin()"
          />
          <mat-datepicker-toggle
            id="picker3icon"
            matSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
          <mat-error
            *ngIf="
                form.controls.expirationDate.touched &&
                form.controls.expirationDate.errors &&
                form.controls.expirationDate.errors.required
              "
          >
            Expiration date is Mandatory.
          </mat-error>
          <mat-error
            *ngIf="
                form.controls.expirationDate.touched &&
                form.controls.expirationDate.errors &&
                form.controls.expirationDate.errors.endDateSmallerThanSysdate
              "
          >
            Expiration date has to be after current date.
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="2%"
        fxLayoutGap.lt-md="0px"
      >
        <mat-form-field
          fxFlex="49%"
          fxFlex.lt-md="100%"
          appearance="standard"
          *ngIf="activityForm.taskType.value === getActivityType().SHIPPER_COUNT.valueOf() ||
                  activityForm.taskType.value === getActivityType().COMPLIANCE.valueOf() ||
                  activityForm.taskType.value === getActivityType().PHYSICAL_STOCK_COUNT.valueOf()"
        >
          <mat-label>Season</mat-label>
          <mat-select formControlName="season" [required]="!isPersonalActivity">
            <mat-option *ngFor="let season of seasons | activeFilter:filterArgs" [value]="season.seasId">
              {{ season.en }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex="49%"
          fxFlex.lt-md="100%"
          appearance="standard"
          *ngIf="activityForm.taskType.value === getActivityType().SHIPPER_COUNT.valueOf() ||
                  activityForm.taskType.value === getActivityType().COMPLIANCE.valueOf() ||
                  activityForm.taskType.value === getActivityType().PHYSICAL_STOCK_COUNT.valueOf()"
        >
          <mat-label>Location</mat-label>
          <mat-select formControlName="location" [required]="!isPersonalActivity">
            <mat-option *ngFor="let location of locations | activeFilter:filterArgs" [value]="location.locId">
              {{ location.en }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex="100%"
          fxFlex.lt-md="100%"
          appearance="standard"
          *ngIf="activityForm.taskType.value === getActivityType().PERSONAL.valueOf()"
        >
          <mat-label>Person Responsible</mat-label>
          <mat-chip-list #chipList>
            <mat-chip
              class="chip-item"
              *ngFor="let person of users"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(person)">
              {{ person.nam }} ({{ person.usrId }})
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              type="text"
              placeholder="Search for person responsible..."
              #userInput
              matInput
              formControlName="personResponsible"
              (focusout)="removeOnFocusOut()"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete
            autoActiveFirstOption #auto="matAutocomplete"
            (closed)="close()"
            (optionSelected)="selectedValue($event)" [displayWith]="valueMapper"
          >
            <mat-option *ngFor="let usr of filteredUsers" [value]="usr">
              {{ usr.nam }} ({{usr.usrId }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <app-load-person-container
        [ngStyle]="{'display': activityForm.taskType.value === getActivityType().PERSONAL.valueOf() ? '' : 'none'}"
        (errorEmitter)="showError($event)"
        (successEmitter)="addUsers($event)"
        [personResponsible]="users"
        [terrs]="terrs"
      ></app-load-person-container>

      <app-store-container
        [ngStyle]="{'display': (isComplianceOrShipperCount() || isPhysicalStockCount()) && chainGroupSelected() ? '' : 'none'}"
        #storeContainer
        [config]="this.buildStoreConfig()"
        (errorEmitter)="showError($event)"
      ></app-store-container>

      <app-generic-attachment
        [config]="attachmentConfig"
        [attachments]="[]"
        [isUrl]="false"
      ></app-generic-attachment>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between center"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
      >
        <mat-form-field
          fxFlex
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label>Activity Title</mat-label>
          <input
            matInput
            formControlName="activityName"
            [maxLength]="getLength().title"
            [readonly]="!isMerchAdmin()"
          />
        </mat-form-field>

        <div
          fxFlex="10%"
          fxFlex.lt-md="100%"
          fxLayoutAlign="end center"
          fxLayoutAlign.lt-md="start"
        >
          <mat-slide-toggle formControlName="internalUseOnly">Internal User</mat-slide-toggle>
        </div>
      </div>
    </form>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
      style="margin-top: 10px"
    >
      <button
        fxFlex="10%"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn btn-actions"
        color="primary"
        type="button"
        [disabled]="this.form.invalid"
        (click)="onSend(child)"
      >
        Save
      </button>
      <button
        fxFlex="10%"
        fxFlex.lt-md="100%"
        style="background-color: #f44336!important"
        mat-raised-button
        class="btn btn-actions"
        color="primary"
        type="button"
        (click)="resetFields()"
      >
        Reset
      </button>
    </div>

  </mat-card>
</div>
