<div
  class="wrapper"
>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    fxLayout="column"
    fxLayoutGap="2%"
    class="mat-elevation-z2"
  >
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutAlign="space-between"
      class="mrg"
    >

      <mat-form-field fxFlex="65" fxFlex.lt-md="100">
        <input
          matInput
          #searchInput
          type="search"
          [(ngModel)]="searchValue"
          (keydown.enter)="getData()"
          placeholder="Search"/>
        <button
          mat-button
          matSuffix
          mat-stroked-button
          (click)="getData()"
          aria-label="search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>

      <button
        fxFlex="30"
        fxFlex.lt-md="100%"
        class="btn-action"
        mat-raised-button
        type="button"
        (click)="openResponseDialog()"
      >ADD NEW RESPONSE SET
      </button>
    </div>

    <!--Table View-->
    <mat-accordion
      displayMode="flat"
      multi
      class="mat-table"
    >
      <mat-card style="text-align: center" *ngIf="dataSource.data.length <= 0">
        <b>No Records Found!</b>
      </mat-card>

      <mat-expansion-panel
        *ngFor="let item of dataSource.data" hideToggle [expanded]="item['expanded']"
        [disabled]="true"
      >
        <mat-expansion-panel-header
          class="header"
        >
          <div
            *ngIf="isBulkDelete"
            fxLayout="column"
            fxLayoutAlign="center"
            class="ml-07"
          >
            <mat-checkbox
              (change)="$event ? selection.toggle(item) : null"
            ></mat-checkbox>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-around center"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="space-between"
            fxFlex="100%"
          >
            <div
              fxLayout="column"
              fxFlex="95%"
              fxFlex.lt-md="100%"
              fxLayoutAlign="space-around flex-start"
              fxLayoutAlign.lt-md="space-around center"
              class="ml-07"
            >
              <span
                class="mat-cell abstract-title"
              >{{ item.title }}</span>

              <span
                class="mat-cell"
              >Responses: {{ item.responses?.length }}</span>
            </div>

            <div
              fxLayout="column"
              fxLayout.lt-md="row"
              fxFlex="8%"
              fxFlex.lt-md="100%"
              fxLayoutAlign="space-around center"
              fxLayoutAlign.lt-md="space-between"
            >
              <button
                mat-button
                color="primary"
                type="button"
                (click)="toggle(item)"
              >
                <div
                  class="material-icons"
                  style="color: #42a5f5 !important;"
                >edit</div>
              </button>

              <button
                mat-button
                color="primary"
                type="button"
                (click)="copyAs(item)"
              >
                <div
                  class="material-icons"
                  style="color: #42a5f5 !important;"
                >file_copy</div>
              </button>

              <button
                mat-button
                color="primary"
                type="button"
                (click)="delete(item)"
              >
                <div
                  class="material-icons"
                  style="color: #f44336 !important;"
                >delete</div>
              </button>
            </div>
          </div>
        </mat-expansion-panel-header>

        <!-- lazy -->
        <ng-template matExpansionPanelContent>
          <app-response-panel #child [config]="buildConfig(item)" [object]="buildObject(item)"
                              (onUpdate)="onUpdate($event)" (onCancel)="onCancel($event, child)">
          </app-response-panel>
        </ng-template>

      </mat-expansion-panel>

    </mat-accordion>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button
        *ngIf="!isBulkDelete"
        fxFlex="150px"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn-bulk-delete"
        color="primary"
        type="button"
        (click)="enableBulkDelete()"
      > Enable Bulk Delete </button>

      <button
        *ngIf="isBulkDelete"
        fxFlex="150px"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn-bulk-delete"
        color="primary"
        type="button"
        [disabled]="this.selection.selected.length < 1"
        (click)="onBulkDelete()"
      > Delete Selected </button>

      <button
        *ngIf="isBulkDelete"
        fxFlex="150px"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn-cancel btn-bulk-delete"
        color="primary"
        type="button"
        (click)="disableBulkDelete()"
      > Cancel </button>
    </div>

    <mat-paginator [hidden]="!dataSource.data.length"
                   [length]="resultsLength"
                   [pageSizeOptions]="[10, 25, 50, 75, 100]" [pageSize]="50"
                   (page)="onPageChange($event)"
    ></mat-paginator>

  </mat-card>

</div>
