import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AzureUsersPage } from '../../shared/models/azure-users-page.model';
import {DefaultResponse} from '../../shared/models/activity/default-response.model';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {PortalUser} from '../../shared/models/portalUser';
import {StringResponse} from '../../shared/models/string-response';
import {Training} from '../../shared/models/training.model';
import {Season} from '../../shared/models/season.model';
import {Location} from '../../shared/models/location.model';
import {Chain, ChainGroupModel} from '../../shared/models/chain';
import {Room} from '../../shared/models/room.model';
import { ResponseGroupType } from '../../shared/models/activity/response-group-type.model';
import { ResponseGroupFilterModel } from '../../shared/models/activity/response-group-filter.model';

@Injectable()
export class AdminService {
  constructor(private http: HttpClient) {}

  findAllDefaultResponses(
    pageIndex: number,
    pageSize: number,
    findByTitle: string
  ): Observable<DefaultResponse[]> {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if ((!pageIndex && pageIndex !== undefined) || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if ((!pageSize && pageSize !== undefined) || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 50);
    }
    if (findByTitle) {
      params = params.append('findByTitle', findByTitle);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<DefaultResponse[]>(
      environment.apiResourceUri + 'responses/default' + apiParams
    );
  }

  update(response: DefaultResponse): Observable<DefaultResponse> {
    return this.http.put<DefaultResponse>(
      environment.apiResourceUri + 'responses/default',
      response
    );
  }

  create(response: DefaultResponse): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(
      environment.apiResourceUri + 'responses/default',
      response
    );
  }

  delete(response: DefaultResponse): Observable<string> {
    return this.http.delete<string>(
      environment.apiResourceUri + 'responses/default/' + response.respGroupId,
      { responseType: 'text' as 'json' }
    );
  }

  bulkDelete(ids: string[]): Observable<any> {
    return this.http.put<any>(environment.apiResourceUri + 'responses/default/bulk', { ids });
  }

  findAllById(respGrpId: string): Observable<DefaultResponse> {
    return this.http.get<DefaultResponse>(environment.apiResourceUri + 'responses/default/' + respGrpId);
  }

  findAll(): Observable<DefaultResponse[]> {
    return this.http.get<DefaultResponse[]>(
      environment.apiResourceUri + 'responses'
    );
  }

  findAllByCriteria(filterData: ResponseGroupFilterModel): Observable<DefaultResponse[]> {
    let params = null;
    params = new HttpParams();

    params = params.append('respGroupType', filterData.respGroupType);
    params = params.append('installationType', filterData.installationType);
    if (filterData.chainGroupId != null) {
      params = params.append('chainGroupId', filterData.chainGroupId);
    }

    return this.http.get<DefaultResponse[]>(
      environment.apiResourceUri + 'responses/filtered', { params: params });
  }

  searchAzureUsers(searchField: string): Observable<AzureUsersPage> {
    const graph_endpoint = `https://graph.microsoft.com/v1.0/users`;
    let headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();

    headers = headers.append('ConsistencyLevel', 'eventual');
    params = params.append('$count', 'true');
    params = params.append(
      '$search',
      '"displayName:' +
      searchField +
      '" OR "mail:' +
      searchField +
      '" OR "employeeId:' +
      searchField +
      '"'
    );
    params = params.append('$orderBy', 'displayName');
    params = params.append('$select', 'id,displayName,mail,onPremisesSamAccountName');
    params = params.append('$top', '50');

    return this.http.get<AzureUsersPage>(graph_endpoint, { headers, params });
  }

  getUsersPortal(pageIndex: number, pageSize: number, search?: string):  Observable<IPagedResponse<PortalUser>>  {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search)
    }
    if ((!pageIndex && pageIndex !== undefined) || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if ((!pageSize && pageSize !== undefined) || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<IPagedResponse<PortalUser>>(
      environment.apiResourceUri + 'portal-users' + apiParams
    );
  }

  createUserPortal(user: PortalUser): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'portal-users',
      user
    );
  }

  editUserPortal(user: PortalUser): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'portal-users',
      user
    );
  }

  deleteUserPortal(user: PortalUser): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      environment.apiResourceUri + 'portal-users/' + user.id,
      { responseType: 'json' }
    );
  }

  getRoles() {
    return this.http.get<String[]>(
      environment.apiResourceUri + 'admin/roles'
    );
  }

  getTerritories() {
    return this.http.get<String[]>(
      environment.apiResourceUri + 'admin/terrs'
    );
  }

  getChains() {
    return this.http.get<Chain[]>(
      environment.apiResourceUri + 'admin/chains'
    );
  }

  getChainsByEmail(email: string): Observable<Chain[]> {
    return this.http.get<Chain[]>(environment.apiResourceUri + 'admin/' + email + '/chains');
  }

  getChainGroupsByEmail(email: string): Observable<ChainGroupModel[]> {
    return this.http.get<ChainGroupModel[]>(environment.apiResourceUri + 'admin/' + email + '/chain-groups');
  }

  getResponseGroupTypes(): Observable<ResponseGroupType[]> {
    return this.http.get<ResponseGroupType[]>(environment.apiResourceUri + 'responses/types');
  }

  getTerrsByEmail(email: string): Observable<string[]> {
    return this.http.get<string[]>(environment.apiResourceUri + 'admin/' + email + '/terrs');
  }

  getTrainings(pageIndex: number, pageSize: number, search?: string):  Observable<IPagedResponse<Training>> {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search);
    }
    if ((!pageIndex && pageIndex !== undefined) || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if ((!pageSize && pageSize !== undefined) || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<IPagedResponse<Training>>(
      environment.apiResourceUri + 'trainings' + apiParams
    );
  }

  deleteTraining(training: Training): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      environment.apiResourceUri + 'trainings/' + training.trngId,
      { responseType: 'json' }
    );
  }

  createTraining(training: Training): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'trainings',
      training
    );
  }

  editTraining(training: Training): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'trainings',
      training
    );
  }

  getLocation(search?: string):  Observable<Location[]> {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<Location[]>(
      environment.apiResourceUri + 'locations/search' + apiParams
    );
  }

  deleteLocation(location: Location): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      environment.apiResourceUri + 'locations/' + location.locId,
      { responseType: 'json' }
    );
  }

  createLocation(location: Location): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'locations',
      location
    );
  }

  editLocation(location: Location): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'locations',
      location
    );
  }

  getSeason(search?: string):  Observable<Season[]> {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<Season[]>(
      environment.apiResourceUri + 'seasons/search' + apiParams
    );
  }

  getResponseGroupType(search?: string):  Observable<ResponseGroupType[]> {
    let apiParams = '';

    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search);
    }

    if (params) {
      apiParams = '?' + params.toString();
    }
    return this.http.get<ResponseGroupType[]>(
      environment.apiResourceUri + 'response-group-types/search' + apiParams
    );
  }

  deleteSeason(season: Season): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      environment.apiResourceUri + 'seasons/' + season.seasId,
      { responseType: 'json' }
    );
  }

  deleteResponseGroupType(responseGroupType: ResponseGroupType): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      environment.apiResourceUri + 'response-group-types/' + responseGroupType.id,
      { responseType: 'json' }
    );
  }

  createSeason(season: Season): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'seasons',
      season
    );
  }

  createResponseGroupType(responseGroupType: ResponseGroupType): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'response-group-types',
      responseGroupType
    );
  }

  editSeason(season: Season): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'seasons',
      season
    );
  }

  editResponseGroupType(responseGroupType: ResponseGroupType): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'response-group-types',
      responseGroupType
    );
  }

  getRooms(pageIndex: number, pageSize: number, search?: string):  Observable<IPagedResponse<Room>>  {
    let params = null;
    params = new HttpParams();
    if (search && search !== undefined && search !== '') {
      params = params.append('search', search);
    }
    if ((!pageIndex && pageIndex !== undefined) || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if ((!pageSize && pageSize !== undefined) || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    return this.http.get<IPagedResponse<Room>>(environment.apiResourceUri + 'rooms/search', { params });
  }

  createRoom(room: Room): Observable<StringResponse> {
    return this.http.post<StringResponse>(
      environment.apiResourceUri + 'rooms',
      room
    );
  }

  editRoom(room: Room): Observable<StringResponse> {
    return this.http.put<StringResponse>(
      environment.apiResourceUri + 'rooms',
      room
    );
  }

  deleteRoom(room: Room): Observable<StringResponse> {
    return this.http.delete<StringResponse>(
      `${environment.apiResourceUri}rooms/${room.id}`,
      { responseType: 'json' }
    );
  }
}
