import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { InstallationType, ResponseGroupType } from '../../../shared/models/activity/response-group-type.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-response-group-type-dialog',
  templateUrl: './response-group-type-dialog.component.html',
  styleUrls: ['./response-group-type-dialog.component.scss']
})
export class ResponseGroupTypeDialogComponent implements OnInit {

  displayedColumns = [
    'desc',
    'action'
  ];

  form: FormGroup;
  action: string;
  application = 'Response Group Type';

  dataSource = new MatTableDataSource<any>();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResponseGroupTypeDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ResponseGroupType
  ) {
    if (data) {
      this.form = this.fb.group({
        id: [data.id, []],
        type: [data.type, [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        installations: this.fb.array(data.installations.map(value => this.fb.group({
          id: new FormControl(value.id, []),
          desc: new FormControl(value.desc, [Validators.required]),
          action: new FormControl('existingRecord'),
          isEditable: new FormControl(true),
          isNewRow: new FormControl(false)
        })))
      });
      this.action = 'Update';
      this.dataSource = new MatTableDataSource((this.form.get('installations') as FormArray).controls);
    } else {
      this.form = this.fb.group({
        id: [null, []],
        type: ['', [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        installations: this.fb.array([])
      });
      this.action = 'Add';
    }
  }

  ngOnInit(): void { }

  onSubmit() {
    let data: ResponseGroupType = null;
    if (this.form.value) {
      data = new ResponseGroupType(this.form.value);
    }
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addNewRecord() {
    const control = this.form.get('installations') as FormArray;
    control.push(this.initiateRecord());
    this.dataSource = new MatTableDataSource(control.controls);
  }

  editRecord(i) {
    (this.form.get('installations')as FormArray).at(i).get('isEditable').patchValue(false);
  }

  saveRecord(i) {
    (this.form.get('installations')as FormArray).at(i).get('isEditable').patchValue(true);
  }

  cancelRecord(i) {
    (this.form.get('installations')as FormArray).at(i).get('isEditable').patchValue(true);
  }

  deleteRecord(i) {
    (this.form.controls.installations as FormArray).removeAt(i);
    this.dataSource = new MatTableDataSource((this.form.get('installations') as FormArray).controls);
  }

  initiateRecord(): FormGroup {
    return this.fb.group({
      id: new FormControl(null, []),
      desc: new FormControl('', [Validators.required]),
      action: new FormControl('newRecord'),
      isEditable: new FormControl(false),
      isNewRow: new FormControl(true),
    });
  }
}
