<div
  class="wrapper"
>
  <app-msg-banner
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    class="mat-elevation-z2"
  >
    <mat-drawer-container class="example-container">
      <mat-expansion-panel [expanded]="true" style="margin-bottom: 10px;">
        <mat-expansion-panel-header>
          <mat-panel-title
            [formGroup]="formSearch">
            <div *ngIf="quick === true">
              <div class="panel-header">
                {{title}}
              </div>
            </div>
            <div *ngIf="advanced ===true"
              style="margin-top: 13px; font-size: 20px !important;">
              Advanced Search Criteria
            </div>

            <button
              mat-raised-button
              fxFlex="165px"
              class="btn btn-search" color="primary"
              style="margin-left: 20px"
              (click)="onAdvancedSearch()"
            >
              Quick Search
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <form
          fxLayout="column"
          [formGroup]="formSearch"
          (ngSubmit)="onSearch()"
          novalidate
        >

          <div
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutGap="2%"
            fxLayoutGap.lt-md="0"
          >
            <mat-form-field
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Account</mat-label>
              <mat-select
                formControlName="chain"
                placeholder="Account"
                [multiple]="true"
                #chainMultiSelect
                (selectionChange)="changeToCompliance()"
                [required]="formSearch.controls.activityType.value?.actTypeId !== activityType.PERSONAL.valueOf()"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleChainsSelectAll($event)"
                    [formControl]="chainMultiFilterCtrl"
                    placeholderLabel="Find account..."
                    noEntriesFoundLabel="'No matching account found'"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let chainGrp of filteredChainsMulti | async"
                  [value]="chainGrp"
                >
                  {{ getChainGroupNameById(chainGrp) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formSearch.controls.chain.errors?.required">
                Account is Mandatory.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Store Number</mat-label>
              <input matInput formControlName="store" maxlength="5"/>
              <mat-error *ngIf="formSearch.controls.store.errors?.pattern && formSearch.controls.store.dirty">
                * Input not allowed
              </mat-error>
            </mat-form-field>

            <mat-form-field
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Region</mat-label>
              <input matInput formControlName="region" maxlength="2"/>
              <mat-error *ngIf="formSearch.controls.region.errors?.pattern && formSearch.controls.region.dirty">
                * Input not allowed
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="!terrRestricted"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Territory</mat-label>
              <input matInput formControlName="territory" maxlength="5"/>
              <mat-error *ngIf="formSearch.controls.territory.errors?.pattern && formSearch.controls.territory.dirty">
                * Input not allowed
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="terrRestricted"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Territory</mat-label>
              <mat-select
                formControlName="territory"
                placeholder="Territory"
                [multiple]="true"
                #terrMultiSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleTerrsSelectAll($event)"
                    [formControl]="terrMultiFilterCtrl"
                    placeholderLabel="Find territory..."
                    noEntriesFoundLabel="'No matching territory found'"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let terr of filteredTerrsMulti | async"
                  [value]="terr"
                >
                  {{ terr }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Compliance Type</mat-label>
              <mat-select
                formControlName="activityType"
                required
              >
                <mat-option *ngFor="let type of activityTypes" [value]="type">
                  {{ getActivityType(type.actTypeId) }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formSearch.controls.activityType.errors?.required">
                Compliance Type is Mandatory.
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity start date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  [formControl]="formSearch.controls['startFirstDate']"
                  placeholder="Activity start date begin"
                />
                <input
                  matEndDate
                  [formControl]="formSearch.controls['startSecondDate']"
                  placeholder="Activity start date end"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error
                *ngIf="
                  formSearch.controls.startFirstDate.hasError(
                    'matStartDateInvalid'
                  )
                "
              >Invalid activity start date begin
              </mat-error
              >
              <mat-error
                *ngIf="
                  formSearch.controls.startFirstDate.hasError(
                    'matEndDateInvalid'
                  )
                "
              >Invalid activity start date end
              </mat-error
              >
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity Season</mat-label>
              <mat-select
                formControlName="activitySeason"
              >
                <mat-option>--All--</mat-option>
                <mat-option
                  *ngFor="let season of seasons | activeFilter: filterArgs"
                  [value]="season"
                >
                  {{ getSeason(season.seasId) }}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity Id</mat-label>
              <input matInput formControlName="activityId" maxlength="20"/>
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity Status</mat-label>
              <mat-select
                formControlName="activityStatus"
                placeholder="Activity Status"
                [multiple]="true"
                #staMultiSelect
                (selectionChange)="onChange($event)"
                required
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleStatusSelectAll($event)"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                    [formControl]="staMultiFilterCtrl"
                    placeholderLabel="Find activity status..."
                    noEntriesFoundLabel="'No matching account found'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let sta of filteredStaMulti | async"
                  [value]="sta"
                >
                  {{ getActivityStatusById(sta) }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced === true"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity end date range</mat-label>
              <mat-date-range-input [rangePicker]="picker2">
                <input
                  matStartDate
                  [formControl]="formSearch.controls['endFirstDate']"
                  placeholder="Activity end date begin"
                />
                <input
                  matEndDate
                  [formControl]="formSearch.controls['endSecondDate']"
                  placeholder="Activity end date end"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker2></mat-date-range-picker>

              <mat-error
                *ngIf="
                  formSearch.controls.endFirstDate.hasError(
                    'matStartDateInvalid'
                  )
                "
              >Invalid activity end date begin
              </mat-error
              >
              <mat-error
                *ngIf="
                  formSearch.controls.endFirstDate.hasError('matEndDateInvalid')
                "
              >Invalid activity end date end
              </mat-error
              >
            </mat-form-field>

            <mat-form-field
              fxFlex="48%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Last Updated On Date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="startDate"
                  placeholder="Last Updated On Date begin"
                />
                <input
                  matEndDate
                  formControlName="endDate"
                  placeholder="Last Updated On Date end"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error
                *ngIf="
                  formSearch.controls.startDate.hasError('matStartDateInvalid')
                "
              >Invalid last updated on date begin
              </mat-error
              >
              <mat-error
                *ngIf="
                  formSearch.controls.endDate.hasError('matEndDateInvalid')
                "
              >Invalid last updated on date end
              </mat-error
              >
            </mat-form-field>

            <mat-form-field
              fxFlex="100%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>All Responses</mat-label>
              <mat-select formControlName="response">
                <mat-option
                  *ngFor="let response of responses"
                  [value]="response"
                  [ngStyle]="
                    { 'background-color': getComplianceInd(response) === null ? '#fff' :
                                          getComplianceInd(response) ? '#f0fff0' : '#fff0f5' }
                  "
                >
                  {{ response }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="advanced===true"
              fxFlex="75%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Activity Title</mat-label>
              <mat-select
                formControlName="name"
                placeholder="Activity Title"
                #singleSelect
                (selectstart)="onDropDownOpened($event)"
                [disabled]="formSearch.invalid"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="titleFilterCtrl"
                    placeholderLabel="Find activity title..."
                    noEntriesFoundLabel="'No matching name found'"
                  ></ngx-mat-select-search>
                </mat-option>
                <div
                  *ngIf="!titles || titles.length === 0"
                  style="height: 50px;"
                >
                  <mat-spinner
                    *ngIf="titles != null"
                    style="margin: 0 auto"
                    [diameter]="40"
                  ></mat-spinner>
                </div>
                <mat-option class="hide-active-option"></mat-option>
                <mat-option *ngFor="let title of filteredTitles | async" [value]="title">
                  {{ title }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox
              *ngIf="advanced === true"
              fxFlex="23%"
              style="align-self: center"
              fxLayoutAlign.lt-md="start start"
              formControlName="expired"
            >Include expired activities
            </mat-checkbox>

            <mat-checkbox
              *ngIf="advanced === true"
              fxHide
              fxFlex="23%"
              style="align-self: center"
              fxLayoutAlign.lt-md="start start"
              formControlName="openPending"
            >Open and pending activities only
            </mat-checkbox>
          </div>

          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxFlex="23%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start start"
            fxLayoutAlign.lt-md="start"
          >
            <button
              fxFlex="100px"
              fxFlex.lt-md="100%"
              mat-raised-button
              class="btn btn-clear"
              color="primary"
              type="button"
              (click)="onSearchReset()"
            >Clear
            </button>
            <button
              fxFlex="100px"
              fxFlex.lt-md="100%"
              mat-raised-button
              class="btn btn-search"
              color="primary"
              type="submit"
              [disabled]="formSearch.invalid"
            >Search
            </button>
          </div>

        </form>

        <mat-action-row
          fxLayout="row"
          fxLayoutGap="5%"
          fxLayoutGap.lt-md="0%"
        >
          <div
            fxFlex="50%"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start"
            fxLayoutGap="0%"
          >
            <div
              class="checkbox"
            >
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
                [disabled]="!activities || activities.length == 0"
              ></mat-checkbox>
            </div>

<!--            <button-->
<!--              fxFlex="50%"-->
<!--              mat-button-->
<!--              class="sort-headers"-->
<!--              [disabled]="!activities || activities.length == 0"-->
<!--              (click)="switchSortHeader('chainStore')"-->
<!--            >-->
<!--              Account-Store-->
<!--              <mat-icon *ngIf="sortHeaders.get('chainStore').localeCompare('asc')"-->
<!--              >arrow_upward-->
<!--              </mat-icon-->
<!--              >-->
<!--              <mat-icon-->
<!--                *ngIf="sortHeaders.get('chainStore').localeCompare('desc')"-->
<!--              >arrow_downward-->
<!--              </mat-icon-->
<!--              >-->
<!--            </button>-->
<!--            <button-->
<!--              fxFlex="50%"-->
<!--              mat-button-->
<!--              class="sort-headers"-->
<!--              [disabled]="!activities || activities.length == 0"-->
<!--              (click)="switchSortHeader('updatedOn')"-->
<!--            >-->
<!--              Updated On-->
<!--              <mat-icon *ngIf="sortHeaders.get('updatedOn').localeCompare('asc')"-->
<!--              >arrow_upward-->
<!--              </mat-icon-->
<!--              >-->
<!--              <mat-icon *ngIf="sortHeaders.get('updatedOn').localeCompare('desc')"-->
<!--              >arrow_downward-->
<!--              </mat-icon-->
<!--              >-->
<!--            </button>-->
          </div>

          <div
            fxFlex="60%"
            fxLayout="row"
            fxLayoutAlign="end center"
          >
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [disabled]="!activities || activities.length == 0"
              aria-label="Options"
              style="margin-right: 10px"
            >
              <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="onSummary()"
              >
                <span>Summary</span>
              </button>
              <button
                mat-menu-item
                (click)="onExport()"
              >
                <span>Export Full Compliance Info</span>
              </button>
              <button
                mat-menu-item
                (click)="onExportItemsAndPhotos()"
              >
                <span>Export Items and Photos</span>
              </button>
              <button
                mat-menu-item
                (click)="onDownload()"
              >
                <span>Export Item Photos</span>
              </button>
            </mat-menu>
          </div>

        </mat-action-row>
      </mat-expansion-panel>

      <app-ghost-activity-view
        *ngIf="(!activities || activities.length === 0) && searchPressed"
      ></app-ghost-activity-view>

      <div
        class="scrollable-view"
        *ngFor="let activity of activities; let i = index"
      >
        <app-activity-view
          [index]="i" [selection]="selection" [activity]="activity" [count]="activities.length"
          [activityTypes]="activityTypes" [seasons]="seasons"
          (refreshEmitter)="refreshSelection($event)"
          (errorEmitter)="displayError($event)"
        >
        </app-activity-view>
      </div>
      <mat-paginator [hidden]="!activities || activities.length === 0 || searchPressed"
                     [pageSizeOptions]="[5, 10, 25, 50, 100]"
                     [pageSize]="10"
                     [length]="totalRows"
                     (page)="onPageChange($event)"
                     showFirstLastButtons
      ></mat-paginator>
    </mat-drawer-container>
  </mat-card>
</div>
