import {AG_ROLE} from './roles';

export const ROUTE = {
  ACTIVITY: {
    NEW: {
      PATH: 'activity/new',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    PUBLISH: {
      PATH: 'activity/publish',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    MANAGE: {
      PATH: 'activity/manage',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS
      ]
    },
    MANAGE_GROUP: {
      PATH: 'activity/manage-group',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
      ]
    },
    PHOTO_COMPLICANCE: {
      PATH: 'activity/compliance',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    PHOTO_COMPLICANCE_QUICK: {
      PATH: 'activity/compliance-quick',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    // PHOTO_COMPLICANCE_DETAILS: {
    //   PATH: 'activity/compliance/details',
    //   ROLE: [
    //     AG_ROLE.UK_MERCH_ADMIN,
    //     AG_ROLE.UK_CUST_OPS,
    //     AG_ROLE.UK_ACCT_MANAGER,
    //     AG_ROLE.UK_TERR_MANAGER,
    //     AG_ROLE.JS_MERCH_ADMIN,
    //     AG_ROLE.JS_IT,
    //     AG_ROLE.JS_MERCHANDISER_MANAGERS,
    //     AG_ROLE.JS_ACCT_MANAGER
    //   ]
    // }
  },
  ADMIN: {
    RESPONSE: {
      PATH: 'admin/response',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.JS_MERCH_ADMIN,
      ]
    },
    USER: {
      PATH: 'admin/user',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_IT,
      ]
    },
    TRAINING: {
      PATH: 'admin/training',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
      ]
    },
    LOCATION: {
      PATH: 'admin/location',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
      ]
    },
    SEASON: {
      PATH: 'admin/season',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
      ]
    },
    RESPONSE_GROUP_TYPE: {
      PATH: 'admin/response-group-type',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
      ]
    },
    ATTACHMENTS: {
      PATH: 'admin/attachments',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    ROOM: {
      PATH: 'admin/rooms',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    DOCUMENTS: {
      PATH: 'admin/documents',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    TERRITORY_CHANGES: {
      PATH: 'admin/territory-changes',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    }
  },
  NOTIFICATION: {
    NEW: {
      PATH: 'notification/new',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    PUBLISH: {
      PATH: 'notification/publish',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
      ]
    },
    VIEW_GENERAL: {
      PATH: 'notification/view/general',
      ROLE: [
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    VIEW_GENERAL_DOCUMENTS: {
      PATH: 'notification/documents/general',
      ROLE: [
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    VIEW_CHAIN: {
      PATH: 'notification/view/account',
      ROLE: [
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    VIEW_CHAIN_DOCUMENTS: {
      PATH: 'notification/documents/account',
      ROLE: [
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ]
    },
    VIEW_TERRITORY: {
      PATH: 'notification/view/territory',
      ROLE: [
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS
      ]
    },
    MANAGE_GENERAL: {
      PATH: 'notification/manage/general',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    MANAGE_CHAIN: {
      PATH: 'notification/manage/account',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ]
    },
    MANAGE_TERRITORY: {
      PATH: 'notification/manage/territory',
      ROLE: [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS
      ]
    }
  },
  ANALYTICS: {
    PATH: 'analytics',
    ROLE: [
      AG_ROLE.UK_MERCH_ADMIN,
      AG_ROLE.JS_IT,
      AG_ROLE.JS_MERCH_ADMIN
    ]
  }
};
