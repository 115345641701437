<div>
  <div id="answers-content"></div>
  <ng-container *ngIf="config.updatable" style="margin-bottom: 10px">
    <form
      fxLayout="column"
      fxLayoutGap="0px"
      [fxLayoutGap.lg]="commReq.checked ? '0px' : '1%'"
      [formGroup]="form"
      novalidate
      style="margin-bottom: 10px;"
    >
      <div
        fxFlex
        fxFlex.lt-md="100%"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="10px"
        fxLayoutGap.lt-md="0"
      >
        <mat-form-field
          fxFlex="100px"
          fxFlex.lt-md="100%"
          class="select-input"
        >
          <mat-label>Compliance flag</mat-label>
          <mat-select
            [(value)]="yes"
            #input
            formControlName="complianceFlag"
            required
          >
            <mat-option value="Y">Y</mat-option>
            <mat-option value="N">N</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          fxFlex
          fxFlex.lt-md="100%"
          appearance="standard"
        >
          <mat-label>Response Message</mat-label>
          <input
            matInput
            #response
            formControlName="response"
            type="text"
            [maxLength]="getLength().response"
            (keyup)="onResponseTyped(response.value)"
          />
          <mat-hint>{{ form.controls.response.value ? form.controls.response.value.length : 0 }}/{{ getLength().response }}</mat-hint>
          <mat-error *ngIf="form.controls.response.errors?.required && form.controls.response.dirty">
            Response Message is Mandatory.
          </mat-error>
          <mat-error *ngIf="form.controls.response.errors?.pattern && form.controls.response.dirty">
            * Input not allowed
          </mat-error>
          <mat-error *ngIf="form.controls.response.errors?.maxLength && form.controls.response.touched">
            {{form.controls.response.errors?.maxLength}}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row wrap"
        fxLayout.lt-md="column"
        fxLayoutAlign="end end"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
        fxLayoutGap.lt-md="0"
      >
        <mat-checkbox #imgReq class="response" fxFlex fxFlex.lt-md="100%" formControlName="imageReq">
          Image required
        </mat-checkbox>
        <mat-checkbox #commReq class="response" fxFlex fxFlex.lt-md="100%" formControlName="commentReq">
          Comment required
        </mat-checkbox>
        <mat-checkbox #preEndDate class="response" fxFlex fxFlex.lt-md="100%" formControlName="preEndDate">
          Pre-end date response allowed
        </mat-checkbox>
      </div>

      <mat-form-field *ngIf="commReq.checked" fxFlex fxFlex.lt-md="100%" fxFlex.md="100%" appearance="standard">
        <mat-label>Comment Prompt</mat-label>
        <input
          matInput
          #commentPrompt
          formControlName="comment"
          type="text"
          [maxLength]="getLength().comment"
        />
        <mat-hint>{{ form.controls.comment.value ? form.controls.comment.value.length : 0 }}/{{ getLength().comment }}</mat-hint>
        <mat-error *ngIf="form.controls.comment.errors?.pattern && form.controls.comment.dirty">
          * Input not allowed
        </mat-error>
        <mat-error *ngIf="form.controls.comment.errors?.maxLength && form.controls.comment.touched">
          {{form.controls.comment.errors?.maxLength}}
        </mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        type="button"
        class="btn btn-add-form"
        [disabled]="this.form.invalid"
        (click)="onClickAddResponse(response.value, input, imgReq, commReq, preEndDate)"
      >
        <i class="material-icons">add_circle_outline</i> Add Response
      </button>
    </form>
  </ng-container>
  <ng-container *ngIf="showAnswers">
    <div
      class="scroll-table mat-elevation-z2"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        class="full-width-table"
      >
        <ng-container matColumnDef="answer">
          <th class="w-300" mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element.desc }}
          </td>
        </ng-container>
        <ng-container matColumnDef="complFlag">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Compliance</th>
          <td mat-cell *matCellDef="let element">
            {{ element.complInd ? 'Y' : 'N' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="imageReq">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Image Required</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.imgReq">check</mat-icon>
            <mat-icon *ngIf="!element.imgReq">close</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="commentReq">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Comment Required</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.cmntReq">check</mat-icon>
            <mat-icon *ngIf="!element.cmntReq">close</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="preEndDate">
          <th class="w-75" mat-header-cell *matHeaderCellDef>Pre-end date response allowed</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.preEndDate">check</mat-icon>
            <mat-icon *ngIf="!element.preEndDate">close</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="comment">
          <th class="w-300" mat-header-cell *matHeaderCellDef>Comment Prompt</th>
          <td mat-cell *matCellDef="let element">{{ element.cmntPpt }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="w-150" mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell
            *matCellDef="let element"
            style="margin: 0 auto"
          >
            <button
              [disabled]="config.disableActions"
              (click)="checkAndEditResponse(element)"
              mat-icon-button
              type="button"
              class="btn-actions update"
            >
              <div
                class="material-icons center-icon"
                style="color: #42a5f5 !important;"
              >
                edit
              </div>
            </button>

            <button
              [disabled]="config.disableActions"
              (click)="checkAndDeleteResponse(element)"
              mat-icon-button
              type="button"
              class="btn-actions delete"
            >
              <div
                class="material-icons center-icon"
                style="color: #f44336 !important;"
              >
                delete
              </div>
            </button>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-container>
</div>
