<mat-card
  fxLayout="column"
  [ngClass]="{
      'mat-elevation-z2': config.data !== null,
      'mat-elevation-z0': config.data === null
  }"
  [ngStyle]="{ 'padding': config.data === null ? '0' : '' }"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

  <form
    [formGroup]="responseSetForm"
    fxLayout="column"
    fxLayoutGap="2%"
  >
    <mat-form-field
      appearance="standard"
    >
      <mat-label>Title</mat-label>
      <input
        matInput
        formControlName="title"
        type="text"
        required
        [maxLength]="getLength().titleOfSet"
      />
      <mat-hint>{{ responseSetForm.controls.title.value ? responseSetForm.controls.title.value.length : 0 }}/{{ getLength().titleOfSet }}</mat-hint>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.required && responseSetForm.controls.title.dirty">
        Title is Mandatory.
      </mat-error>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.pattern && responseSetForm.controls.title.dirty">
        * Input not allowed
      </mat-error>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.maxLength && responseSetForm.controls.title.touched">
        {{responseSetForm.controls.title.errors?.maxLength}}
      </mat-error>
    </mat-form-field>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayout.md="column"
      fxLayoutGap="2%"
    >
      <mat-form-field
        appearance="standard"
        class="w-100"
      >
        <mat-label>Customer Account</mat-label>
        <mat-select formControlName="chainGroupId">
          <mat-option>--All--</mat-option>
          <mat-option *ngFor="let group of chainGroups" [value]="group.groupId"> {{ group.desc }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        class="w-100"
      >
        <mat-label>Response Set Activity Type</mat-label>
        <mat-select
          formControlName="respGroupType"
          (selectionChange)="updateInstallationType(); clearInstallationType()"
          required
        >
          <mat-option>--All--</mat-option>
          <mat-option *ngFor="let type of responseGroupTypes" [value]="type.id"> {{ type.type }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        class="w-100"
      >
        <mat-label>Installation Activity Type</mat-label>
        <mat-select
          formControlName="installationType"
          [disabled]="!respGrpTypeSelected()"
          required
        >
          <mat-option>--All--</mat-option>
          <mat-option *ngFor="let installation of installationTypes" [value]="installation.id"> {{ installation.desc }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        class="w-100"
        *ngIf="object?.updated"
      >
        <mat-label>Update date</mat-label>
        <input
          matInput
          type="text"
          disabled
          [value]="object?.updated | dateFormat"
        />
      </mat-form-field>
    </div>

    <app-load-responses #child [config]="config"></app-load-responses>
  </form>

  <div
    *ngIf="object?.respGroupId !== null"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="2%"
    fxLayoutGap.lt-md="0"
    fxLayoutAlign="center"
  >
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="change(child, 'update')"
      class="btn btn-actions update"
      type="button"
      [disabled]="this.responseSetForm.invalid"
    >Update Set
    </button>
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="cancel()"
      class="btn btn-actions delete" type="button"
    >Cancel
    </button>
  </div>

  <div
    *ngIf="object?.respGroupId === null"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="change(child, 'create')"
      class="btn btn-actions update"
      type="button"
      [disabled]="this.responseSetForm.invalid"
    >
      Create Set
    </button>
  </div>
</mat-card>
