import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AG_ROLE} from '../../constants/roles';
import {AttachmentConfig, AttachmentView} from '../../models/attachment.config';
import {LinkUrlComponent} from '../link-url/link-url.component';
import {AttachmentService} from '../../../core/services/attachment.service';
import {GenericAttachmentDialogComponent} from '../generic-attachment-dialog/generic-attachment-dialog.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RoleGuardService} from '../../../core/guards/role-guard.service';

@Component({
  selector: 'app-generic-attachment',
  templateUrl: './generic-attachment.component.html',
  styleUrls: ['./generic-attachment.component.scss']
})
export class GenericAttachmentComponent implements OnInit {

  @Input() config: AttachmentConfig;
  @Input() attachments: string[];
  @Input() isUrl;
  @Output() errorEmitter: EventEmitter<string> = new EventEmitter<string>();

  isLoading: { [key: number]: boolean } = {};

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private roleGuardService: RoleGuardService,
              private attachmentService: AttachmentService) { }

  ngOnInit(): void {
  }

  get attachmentView() {
    return AttachmentView;
  }

  uploadFile() {
    this.dialog.open(GenericAttachmentDialogComponent, {
      data: this.config,
      disableClose: true
    }).afterClosed().subscribe(response => {
      if (response) {
        this.attachments.push(response);
        this.isUrl = false;
      }
    });
  }

  linkFile() {
    this.config.selected = this.attachments.length;
    this.dialog.open(GenericAttachmentDialogComponent, {
      data: this.config,
      disableClose: true
    }).afterClosed().subscribe((response: string[]) => {
      if (response && response.length > 0) {
        response.forEach(r => {
          if (this.attachments.includes(r)) {
            return;
          }
          this.attachments.push(r);
        });
        this.isUrl = false;
      }
    });
  }

  buildGuardParamBasedRole() {
    const customRoles = [
      AG_ROLE.UK_CUST_OPS, AG_ROLE.UK_ACCT_MANAGER, AG_ROLE.UK_TERR_MANAGER,
      AG_ROLE.JS_ACCT_MANAGER, AG_ROLE.JS_MERCHANDISER_MANAGERS
    ];
    return {
      customeRole: customRoles,
      path: ''
    };
  }

  buildGuardParamAdminRole() {
    const customRoles = [
      AG_ROLE.UK_MERCH_ADMIN,
      AG_ROLE.JS_IT, AG_ROLE.JS_MERCH_ADMIN
    ];
    return {
      customeRole: customRoles,
      path: ''
    };
  }

  delete(index: number) {
    let text = '';
    if (this.isUrl) {
      text = 'Do you want to delete this URL: ' + this.attachments[index] + '?';
    } else {
      text = 'Do you want to delete this file: ' + this.attachments[index] + '?';
    }

    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(text, true, false, true),
      disableClose: true,
    }).afterClosed().subscribe(response => {
      if (response) {
        this.attachments.splice(index, 1);
        this.isUrl = false;
      }
    });
  }

  addUrl() {
    this.config.text = '';
    this.dialog.open(LinkUrlComponent, {
      data: this.config,
      disableClose: true
    }).afterClosed().subscribe(response => {
      if (response && response.replace(/\s/g, '').length > 0) {
        this.attachments.push(response);
        this.isUrl = true;
      }
    });
  }

  openLink(index: number) {
    if (this.config.view === this.attachmentView.ROOM_DOCUMENTS) {
      return;
    }

    if (this.isUrl) {
      window.open(this.attachments[index]);
    } else {
      this.errorEmitter.emit(null);
      this.isLoading[index] = true;

      let type = this.config.view.valueOf();
      if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
        || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
        type = AttachmentView.NOTIFICATION;
      }

      this.attachmentService.downloadFile(this.attachments[index], type).subscribe(
        response => {
          this.isLoading[index] = false;
          this.attachmentService.download(response);
        }, error => {
          let errorText = '';
          if (error.status === 400) {
            errorText = error.error.message;
          } else if (error.status === 404) {
            errorText = 'File not found. Please contact your administrator.';
          } else {
            errorText = 'An error has occurred. Please contact your administrator.';
          }

          this.isLoading[index] = false;
          this.snackBar.open(errorText, 'Close', {
            duration: 10 * 1000
          });
        }
      );
    }
  }

  edit(index: number, attachment: string) {
    this.config.text = attachment;
    this.dialog.open(LinkUrlComponent, {
      data: this.config,
      disableClose: true
    }).afterClosed().subscribe(response => {
      if (response && response.replace(/\s/g, '').length > 0) {
        this.attachments[index] = response;
        this.isUrl = true;
      }
    });
  }
}
