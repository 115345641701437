import {DndDirective} from './components/generic-upload-file/dnd.directive';
import {FileProgressComponent} from './components/generic-upload-file/file-progress/file-progress.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FileTreeDatabase, GenericFileStructureComponent} from './components/generic-file-structure/generic-file-structure.component';
import { GenericUploadFileComponent } from './components/generic-upload-file/generic-upload-file.component';
import { GenericAttachmentComponent } from './components/generic-attachment/generic-attachment.component';
import { GenericAttachmentDialogComponent } from './components/generic-attachment-dialog/generic-attachment-dialog.component';
import { LinkUrlComponent } from './components/link-url/link-url.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../modules/material.module';
import { EditDialogComponent } from './components/edit-dialog/edit-dialog.component';
import {CKEditorModule} from 'ckeditor4-angular';
import {UserRoleDirective} from '../core/directives/user-role.directive';
import {MsgBannerComponent} from './components/msg-banner/msg-banner.component';
import {DateFormatPipe} from '../core/pipes/date-formater-pipe';
import {TextareaLengthFormat} from '../core/pipes/textarea-length-pipe';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {GenericLineItemViewComponent} from './components/generic-line-item-view/generic-line-item-view.component';
import {StoreContainerComponent} from './components/store-container/store-container.component';
import {AbstractNotificationComponent} from '../modules/pages/notification/abstract-notification/abstract-notification.component';
import {NewNotificationComponent} from '../modules/pages/notification/new-notification/new-notification.component';
import { LoadPersonContainerComponent } from './components/load-person-container/load-person-container.component';
import { LoadStoreChainComponent } from './components/load-store-chain/load-store-chain.component';
import { EditStockCountDateComponent } from './components/edit-stock-count-date/edit-stock-count-date.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ConfirmationDialogComponent,
    EditDialogComponent,
    GenericLineItemViewComponent,
    MsgBannerComponent,
    NewNotificationComponent,
    AbstractNotificationComponent,
    DateFormatPipe,
    TextareaLengthFormat,
    StoreContainerComponent,
    DndDirective,
    FileProgressComponent,
    GenericFileStructureComponent,
    GenericUploadFileComponent,
    GenericAttachmentComponent,
    GenericAttachmentDialogComponent,
    LinkUrlComponent,
    UserRoleDirective,
    NotAuthorizedComponent,
    NotFoundComponent,
    LoadPersonContainerComponent,
    LoadStoreChainComponent,
    EditStockCountDateComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    CKEditorModule,
    MatProgressBarModule
  ],
    exports: [
        ConfirmationDialogComponent,
        EditDialogComponent,
        SidebarComponent,
        GenericLineItemViewComponent,
        MsgBannerComponent,
        NewNotificationComponent,
        AbstractNotificationComponent,
        DateFormatPipe,
        TextareaLengthFormat,
        StoreContainerComponent,
        MaterialModule,
        UserRoleDirective,
        GenericFileStructureComponent,
        GenericUploadFileComponent,
        GenericAttachmentComponent,
        LoadPersonContainerComponent
    ],
  providers: [FileTreeDatabase],
})
export class SharedModule { }
