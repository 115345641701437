<div class="wrapper">
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <div fxLayout="column" fxLayoutAlign="space-between stretch">

    <mat-card
      class="mat-elevation-z2"
    >
      <mat-drawer-container class="example-container">
        <mat-expansion-panel [expanded]="true" disabled>
          <mat-expansion-panel-header>
            <div
              fxLayout="row "
              fxLayoutAlign="space-around center"
            >
              <mat-panel-title> Quick Search</mat-panel-title>
              <mat-panel-description>
                <button
                  fxFlex="165px"
                  fxFlex.lt-md="100%"
                  mat-raised-button
                  class="btn btn-search"
                  color="primary"
                  (click)="clickAdvancedButton()"
                > Advanced Search
                </button>
              </mat-panel-description>
            </div>
          </mat-expansion-panel-header>

          <form
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="2%"
            fxLayoutGap.lt-md="0"
            [formGroup]="formSearch"
          >
            <mat-form-field
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Select Account</mat-label>
              <mat-select
                formControlName="chainGroup"
                required
              >
                <mat-option
                  *ngFor="let chain of chainGroups" [value]="chain">
                  {{chain.desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              *ngIf="terrRestricted"
              fxFlex="23%"
              fxFlex.lt-md="100%"
              appearance="standard"
            >
              <mat-label>Territory</mat-label>
              <mat-select
                formControlName="territory"
                placeholder="Territory"
                [multiple]="true"
                required
                #terrMultiSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleTerrsSelectAll($event)"
                    [formControl]="terrMultiFilterCtrl"
                    placeholderLabel="Find territory..."
                    noEntriesFoundLabel="'No matching territory found'"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let terr of filteredTerrsMulti | async"
                  [value]="terr"
                >
                  {{ terr }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox
              style="align-self: center"
              fxLayoutAlign.lt-md="100%"
              formControlName="expired"
              #checkbox
            >
              <span style="white-space: break-spaces;">
                Include expired activities
              </span>
            </mat-checkbox>

            <mat-checkbox
              style="align-self: center"
              fxLayoutAlign.lt-md="100%"
              formControlName="openPending"
            >
              <span style="white-space: break-spaces;">
                Open and pending activities only
              </span>
            </mat-checkbox>
          </form>

          <div
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="start center"
            fxLayoutAlign.lt-md="start"
          >
            <button
              fxFlex="100px"
              fxFlex.lt-md="100%"
              type="reset"
              mat-raised-button
              class="btn btn-clear"
              color="primary"
              (click)="onSearchReset()"
            >Clear
            </button>
            <button

              fxFlex="100px"
              fxFlex.lt-md="100%"
              mat-raised-button
              class="btn btn-search"
              color="primary"
              type="submit"
              [disabled]="!formSearch.valid"
              (click)="getTitles()"
            >Search
            </button>
          </div>
        </mat-expansion-panel>
      </mat-drawer-container>
    </mat-card>


    <div
      class="card" [formGroup]="formSearch"
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="10px"
    >
      <div
        fxLayout="column"
        fxLayoutGap="13px"
        style="background: #EEEEEE"
      >
        <div *ngIf="notFound" class="card" style="padding: 10px;">
          <b style="text-align: center;">Not records found</b>
        </div>

        <mat-accordion class="mat-accordion-spacing" [multi]="true">
          <mat-expansion-panel *ngFor="let dat of groupedActivityTitles | keyvalue; let i = index" [expanded]="true">
            <mat-expansion-panel-header
              class="header-color"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-panel-title
                class="title-color"
              >{{ dat.key | date: 'longDate' }}</mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="activity-card"
                *ngFor="let t of dat.value"
              >
                <div fxLayout="row">
                  <div
                    fxLayout="row"
                    [ngClass]="{'red' : t.color === 'red',
                    'green' : t.color === 'green' ,
                    'orange': t.color === 'orange'}"
                  >
                  </div>
                  <div fxFlex>
                    <mat-card class="title" (click)="clickCard(t)"> {{t.title}}</mat-card>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngFor="let dat of expiredGroupedActivityTitles | keyvalue; let i = index" [expanded]="true">
            <mat-expansion-panel-header
              class="header-color"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-panel-title
                class="title-color"
              >{{ dat.key | date: 'longDate' }}</mat-panel-title>
            </mat-expansion-panel-header>

            <div>
              <div
                class="activity-card"
                *ngFor="let t of dat.value"
              >
                <div fxLayout="row">
                  <div
                    fxLayout="row"
                    [ngClass]="{'red' : t.color === 'red',
                    'green' : t.color === 'green' ,
                    'orange': t.color === 'orange'}"
                  >
                  </div>
                  <div fxFlex>
                    <mat-card class="title" (click)="clickCard(t)"> {{t.title}}</mat-card>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
