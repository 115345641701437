import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { FindActivityService } from '../../../../core/services/find-activity.service';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import { Season } from '../../../../shared/models/season.model';
import { MetricsDialogComponent } from '../metrics-dialog/metrics-dialog.component';
import { PhotoDownloaderService } from '../../../../core/services/photo-downloader.service';
import { Router } from '@angular/router';
import { ActivityType } from '../../../../shared/models/activity/activity-type.model';
import { NewActivityService } from '../../../../core/services/new-activity.service';
import { ActivityComplianceService } from '../../../../core/services/activity-compliance.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Chain } from '../../../../shared/models/chain';
import { GraphService } from '../../../../core/graph/graph.service';
import { take, takeUntil } from 'rxjs/operators';
import { AdminService } from '../../../../core/services/admin.service';
import { ComplianceFilter } from '../../../../shared/models/activity/compliance-filter.model';
import { ActivityCompliance } from '../../../../shared/models/activity/activity-compliance.model';
import { Export } from '../../../../shared/models/activity/export.model';
import { ExcelService } from '../../../../core/services/excel.service';
import { UtilsService } from '../../../../core/services/utils.service';
import { environment } from '../../../../../environments/environment';
import { Response } from '../../../../shared/models/activity/response.model';
import { ImageZip } from '../../../../shared/models/activity/image-zip.model';
import { DatePipe } from '@angular/common';
import { CloudfrontService } from '../../../../core/services/cloudfront.service';
import { ActivityStatus, ActivityStatusEnum } from '../../../../shared/models/activity/activity-status.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '../../../../shared/models/dialog';
import { ChainGroupModel } from '../../../../shared/models/activity/chain-group.model';
import { ActivityTypes } from '../../../../shared/constants/ActivityTypes';
import { User } from '../../../../shared/models/activity/user.model';
import { UserService } from '../../../../core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
})
export class ActivityDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;
  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<number[]> = new ReplaySubject<
    number[]
    >(1);
  public tooltipMessage = 'Select All / Unselect All';
  chainRestricted = true;
  chains: Chain[] = [];
  chainGroups: ChainGroupModel[];
  quickSearchChainGrpId: number;

  @ViewChild('staMultiSelect', { static: true }) staMultiSelect: MatSelect;
  public staMultiFilterCtrl: FormControl = new FormControl();
  public filteredStaMulti: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

  @ViewChild('terrMultiSelect', { static: true }) terrMultiSelect: MatSelect;
  public terrMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerrsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  terrRestricted = true;
  terrs: string[] = [];
  protected _onDestroy = new Subject<void>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = [
    'activityName',
    'activityType',
    'activitySeason',
    'responses',
    'metrics',
  ];
  totalRows: number;
  complianceFormData: ComplianceFilter;
  formSearch: FormGroup;
  childSubscription: Subscription;

  activityStatuses = [];

  panelOpenStateSecond = true;
  isLoading = true;
  searchDisabled = false;
  searchPressed = false;
  isLoadingResults = false;
  isError = false;
  firstLoad = true;

  showNotification = false;
  messageList = [];

  seasons: Season[] = [];
  activityTypes: ActivityType[] = [];

  activities: ActivityCompliance[] = [];

  responses = [];
  fullResponses: Response[] = [];
  title: string;
  account: string[];
  route: string;
  quick: Boolean = false;
  advanced: Boolean = false;

  titleSubscribe: any;
  public titleFilterCtrl: FormControl = new FormControl();
  public filteredTitles: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  titles: string[] = [];
  totalElements = 0;

  filterArgs = { active: true };
  filteredUsers: User[] = [];

  selection = new SelectionModel<ActivityCompliance>(true, []);

  sortHeaders: Map<string, string> = new Map([
    ['chainStore', 'asc'],
    ['updatedOn', 'desc'],
  ]);

  selectItem = (data) => {
    data.isSelected = !data.isSelected;
    const list = this.activities.filter(
      (activity) => activity.isSelected === true
    );
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private msgBanner: MsgBannerService,
    private findActivityService: FindActivityService,
    private newActivityService: NewActivityService,
    private cloudFrontService: CloudfrontService,
    private photoDownloaderService: PhotoDownloaderService,
    private activityComplianceService: ActivityComplianceService,
    private graphService: GraphService,
    private adminService: AdminService,
    private excelService: ExcelService,
    private snackBar: MatSnackBar,
    private router: Router,
    private datePipe: DatePipe
  ) {
  this.createSearchForm();
  }

  ngOnInit(): void {
    if (history.state.data) {
      this.title = history.state.data.title;
      this.complianceFormData = history.state.data;
      this.complianceFormData.updatedAsc = false;
      this.complianceFormData.chainStoreAsc = true;
      this.quickSearchChainGrpId = history.state.data.chainGrpId;
    } else {
      this.router.navigate(['/activity/compliance-quick']);
      return;
    }
    if (this.complianceFormData.route === 'quick') {
      this.quick = true;
    } else if (this.complianceFormData.route === 'advanced') {
      this.advanced = true;
    }
    this.createSearchForm();

    this.loadSeason();
    this.loadTaskType();
    this.loadActivityStatus();

    Promise.all(this.getSignedUrls()).then(() => {
      Promise.all(this.getChainsAndTerrs(this.graphService.user.displayName)).then(() => {
        this.initDropDownList();

        if (this.quick) {
          this.getData();
          this.formSearch.controls.activityType.setValidators([]);
          this.formSearch.controls.activityType.updateValueAndValidity();
          this.formSearch.controls.activityStatus.setValidators([]);
          this.formSearch.controls.activityStatus.updateValueAndValidity();
        } else {
          this.initValidators();
        }

      });
    });

    this.initializeResponses();
    this.formSearch.controls.response.setValue('All');
  }

  initValidators() {
    this.formSearch.controls.activityType.valueChanges.subscribe((value) => {
      if (value?.actTypeId === ActivityTypes.PERSONAL.valueOf()) {
        this.formSearch.controls.chain.setValue(null);
        this.formSearch.controls.chain.setValidators([]);
        this.formSearch.controls.chain.updateValueAndValidity();
        this.formSearch.controls.territory.setValue(null);
        this.formSearch.controls.territory.updateValueAndValidity();
      } else {
        this.formSearch.controls.chain.setValidators([Validators.required]);
        this.formSearch.controls.chain.updateValueAndValidity();
      }
    });
  }

  getTitles() {
    this.initSearch();
    this.initRestrictedFilters();
    this.showNotification = false;
    return this.activityComplianceService.getTitles(this.complianceFormData).subscribe(
      response => {
        this.titles = response;
        if (this.titles?.length === 0) {
          this.titles = null;
        }
        this.initTitleAutocomplete();
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  initTitleAutocomplete() {
    this.filteredTitles.next(this.titles);

    this.titleFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTitles();
      });
  }

  protected filterTitles() {
    if (!this.titles) {
      return;
    }

    let search = this.titleFilterCtrl.value;
    if (!search) {
      const titles: string[] = this.titles;

      // if (this.formSearch.controls.name.value) {
      //   const index = titles.indexOf(this.formSearch.controls.name.value);
      //   if (index === -1) {
      //     titles.unshift(this.formSearch.controls.name.value);
      //     titles.pop();
      //   } else {
      //     titles.splice(index, 1);
      //     titles.unshift(this.formSearch.controls.name.value);
      //   }
      // }

      this.filteredTitles.next(titles);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.totalElements = this.titles.filter(title => title.toLowerCase().indexOf(search) > -1).length;
    this.filteredTitles.next(
      this.titles
        .filter(title => title.toLowerCase().indexOf(search) > -1)
    );
  }

  initializeResponses() {
    this.responses = [
      'All',
      'No response'
    ];
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredTitles
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.singleSelect) {
          this.singleSelect.compareWith = (a: string, b: string) => a && b && a === b;
        }
      });
  }

  getSignedUrls(): Promise<boolean>[] {
    this.showNotification = false;
    const promiseList: Promise<boolean>[] = [];

    if (!CloudfrontService.checkIfExistsOrExpired(environment.resizedImagesBucket)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.cloudFrontService.getSignedUrl(environment.resizedImagesBucket).subscribe(
            result => {
              release(true);
            }, error => {
              if (error.status === 500) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
              reject(error);
            }
          );
        })
      );
    }

    if (!CloudfrontService.checkIfExistsOrExpired(environment.imagesBucket)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.cloudFrontService.getSignedUrl(environment.imagesBucket).subscribe(
            result => {
              release(true);
            }, error => {
              if (error.status === 500) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
              reject(error);
            }
          );
        })
      );
    }

    return promiseList;
  }

  getChainsAndTerrs(displayName: string): Promise<boolean>[] {
    this.showNotification = false;
    const promiseList: Promise<boolean>[] = [];

    // chains
    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getChainsByEmail(displayName).subscribe(
          response => {
            this.chains = response;
            if (response.length === 0) {
              this.chainRestricted = false;
            } else {
              this.chainRestricted = true;
            }
            release(true);
          }, error => {
            if (error.status === 404) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            reject(error);
          }
        );
      })
    );

    // chain groups
    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getChainGroupsByEmail(displayName).subscribe(
          (result : ChainGroupModel[]) => {
            this.chainGroups = result;
            release(true);
          },
          (error: any) => {
            this.chainGroups = [];
            release(false);
          }
        );
      }));

    // terrs
    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getTerrsByEmail(displayName).subscribe(
          response => {
            this.terrs = response;
            if (response.length === 0) {
              this.terrRestricted = false;
            } else {
              this.terrRestricted = true;
            }

            release(true);
          }, error => {
            if (error.status === 404) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            reject(error);
          }
        );
      })
    );

    return promiseList;
  }

  private initDropDownList() {

    if (!this.advanced) {
      this.formSearch.controls.chain.setValue([history.state.data.chainGrpId]);
      this.formSearch.controls.chain.disable();
      this.formSearch.controls.chain.updateValueAndValidity();

      this.formSearch.controls.territory.setValue(history.state.data.terr);
      this.formSearch.controls.territory.updateValueAndValidity();
    }

    this.filteredChainsMulti.next(this.chainGroups.map(chainGrp => chainGrp.groupId));
    this.chainMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterChainsMulti();
    });

    if (this.terrRestricted) {
      this.filteredTerrsMulti.next(this.terrs);
      this.terrMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTerrsMulti();
        });
    }
  }

  getChainNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId === chainId).chainName;
  }

  getChainGroupNameById(chainGrpId: number) {
    return this.chainGroups.find((chainGrp: ChainGroupModel) => chainGrp.groupId === chainGrpId).desc;
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.formSearch.controls.chain.patchValue(val);
        } else {
          this.formSearch.controls.chain.patchValue([]);
        }
      });
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerrsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.formSearch.controls.territory.patchValue(val);
        } else {
          this.formSearch.controls.territory.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chainGroups.map(chainGrp => chainGrp.groupId));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chainGroups
        .filter(
          (chainGrp) =>
            this.getChainGroupNameById(chainGrp.groupId)
              .toLowerCase()
              .indexOf(search) > -1
        ).map(chainGrp => chainGrp.groupId)
    );
  }

  protected filterTerrsMulti() {
    if (!this.terrs) {
      return;
    }
    // get the search keyword
    let search = this.terrMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerrsMulti.next(this.terrs);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the terrs
    this.filteredTerrsMulti.next(
      this.terrs
        .filter((territory) => territory.toLowerCase().indexOf(search) > -1)
    );
  }

  checkForMatch(array, propertyToMatch, valueToMatch) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToMatch] !== valueToMatch) {
        return true;
      }
    }
    return false;
  }

  private getResponses(activityGroupId: string) {
    this.findActivityService.getResponses(activityGroupId).subscribe(
      response => {
        this.fullResponses = response;
        response.forEach(resp => {
          this.responses.push(resp.desc);
        });
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  loadActivityStatus() {
    this.newActivityService.getActivityStatuses().subscribe(
      result => {
        this.activityStatuses = result;
        this.initActivityStatuses();
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  get activityType() {
    return ActivityTypes;
  }

  loadTaskType() {
    this.showNotification = false;
    this.newActivityService.getActivityType().subscribe(
      (result) => {
        this.activityTypes = result;
        if (this.quick) {
          this.formSearch.controls.activityType.setValue(this.activityTypes
              .filter(r => r.actTypeId === ActivityTypes.COMPLIANCE
                || r.actTypeId === ActivityTypes.SHIPPER_COUNT)
          );
        }
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(
            this.messageList,
            'An error has occurred. Please contact your administrator!'
          );
        }
        this.showNotification = true;
      }
    );
  }

  loadSeason() {
    this.showNotification = false;
    this.newActivityService.getSeasons().subscribe(
      (result) => {
        this.seasons = result;
      },
      (error) => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(
            this.messageList,
            'An error has occurred. Please contact your administrator!'
          );
        }
        this.showNotification = true;
      }
    );
  }

  private createSearchForm(): void {
    this.formSearch = this.fb.group({
      chain: [ null, [ Validators.required ]],
      store: this.complianceFormData ? this.complianceFormData.str : ['', Validators.pattern(environment.emojiRegEx)],
      territory: '',
      region: this.complianceFormData ? this.complianceFormData.region : ['', Validators.pattern(environment.emojiRegEx)],
      response: '',
      startDate: '',
      endDate: '',
      name: this.complianceFormData ? this.complianceFormData.title : ['', Validators.pattern(environment.emojiRegEx)],
      expired: this.complianceFormData ? this.complianceFormData.expired : false,
      openPending: this.complianceFormData ? this.complianceFormData.openPending : false,
      title: this.complianceFormData ? this.complianceFormData.title : ['', Validators.pattern(environment.emojiRegEx)],
      activityId: '',
      activityStatus: [ null, [ Validators.required ]],
      activitySeason: '',
      activityType: [ null, [ Validators.required ]],
      startFirstDate: null,
      startSecondDate: null,
      endFirstDate: null,
      endSecondDate: null
    });

    this.formSearch.controls['chain'].setValue(this.complianceFormData?.chain?.length === 0 ? null : this.complianceFormData?.chain);
    this.formSearch.controls['territory'].setValue(this.complianceFormData?.terr);
  }

  onSearch() {
    Promise.all(this.getSignedUrls()).then(() => {
      this.searchPressed = true;
      this.showNotification = false;
      this.initSearch();
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
      this.getData();
      // this.getTitles();
      this.selection.clear();
    });
  }

  onSearchReset() {
    this.formSearch.reset();
    this.initializeResponses();
    this.formSearch.controls.response.setValue('All');
    if (!this.advanced) {
      this.formSearch.controls.chain.setValue([this.quickSearchChainGrpId]);
      this.formSearch.controls.name.setValue(history.state.data.title);
    }
    this.resetComplianceForm();
    this.searchDisabled = false;
    // this.formSearch.controls.territory.setValue(this.terrs);
    // this.onSearch();
  }

  resetComplianceForm() {
    this.complianceFormData.str = this.formSearch.controls.store.value;
    this.complianceFormData.region = this.formSearch.controls.region.value;
    this.complianceFormData.response = null;
    this.complianceFormData.title = this.formSearch.controls.name.value;
    this.complianceFormData.actId = null;
    this.complianceFormData.sta = null;
    this.complianceFormData.seas = null;
    this.complianceFormData.actType = null;
    this.complianceFormData.startFirstDate = null;
    this.complianceFormData.startSecondDate = null;
    this.complianceFormData.endFirstDate = null;
    this.complianceFormData.endSecondDate = null;
    this.complianceFormData.expired = false;
    this.complianceFormData.openPending = false;
  }

  onAdvancedSearch() {
    if (this.quick) {
      const chainGrp: any = this.complianceFormData.chainGrpId;
      this.router.navigate(['activity/compliance-quick'], {
        state: {
          data: {
            chainGrpId: chainGrp instanceof Array ? chainGrp[0] : chainGrp,
            terr: this.complianceFormData.terr,
            expired: this.complianceFormData.expired,
            openPending: this.complianceFormData.openPending
          }
        }
      });
    } else {
      this.router.navigate(['activity/compliance-quick']);
    }
  }

  onPageChange(event: PageEvent) {
    Promise.all(this.getSignedUrls()).then(() => {
      this.getActivities(event.pageIndex, event.pageSize);
    });
  }

  switchSortHeader(id: string) {
    this.sortHeaders.set(
      id,
      this.sortHeaders.get(id).localeCompare('asc') === 0 ? 'desc' : 'asc'
    );

    switch (id) {
      case 'updatedOn':
        this.complianceFormData.updatedAsc = this.sortHeaders.get(id) === 'asc';
        break;
      case 'chainStore':
        this.complianceFormData.chainStoreAsc = this.sortHeaders.get(id) === 'asc';
        break;
    }
    this.onSearch();
  }

  sortData(sort: Sort) {
    console.log(sort);
  }

  initSearch() {
    this.complianceFormData.title = this.formSearch.controls.name.value;
    this.complianceFormData.chainGrpId = this.formSearch.controls.chain.value;
    this.complianceFormData.str = this.formSearch.controls.store.value;
    this.complianceFormData.terr = this.formSearch.controls.territory.value;
    this.complianceFormData.region = this.formSearch.controls.region.value;
    this.complianceFormData.response = this.formSearch.controls.response.value;
    this.complianceFormData.responseType = this.getComplianceInd(this.formSearch.controls.response.value);
    this.complianceFormData.updatedFirstDate = this.formSearch.controls.startDate.value;
    this.complianceFormData.updatedSecondDate = this.formSearch.controls.endDate.value;
    this.complianceFormData.actId = this.formSearch.controls.activityId.value;
    this.complianceFormData.sta = this.formSearch.controls.activityStatus.value === '' ? null : this.formSearch.controls.activityStatus.value;
    this.complianceFormData.seas = this.formSearch.controls.activitySeason.value?.seasId;
    this.complianceFormData.actType = this.formSearch.controls.activityType.value?.actTypeId;
    this.complianceFormData.startFirstDate = this.formSearch.controls.startFirstDate.value ? this.convertDate(this.formSearch.controls.startFirstDate.value) : null;
    this.complianceFormData.startSecondDate = this.formSearch.controls.startSecondDate.value ? this.convertDate(this.formSearch.controls.startSecondDate.value) : null;
    this.complianceFormData.endFirstDate = this.formSearch.controls.endFirstDate.value ? this.convertDate(this.formSearch.controls.endFirstDate.value) : null;
    this.complianceFormData.endSecondDate = this.formSearch.controls.endSecondDate.value ? this.convertDate(this.formSearch.controls.endSecondDate.value) : null;
    this.complianceFormData.expired = this.formSearch.controls.expired.value === true ? this.formSearch.controls.expired.value : false;
    this.complianceFormData.openPending = this.formSearch.controls.openPending.value === true ? this.formSearch.controls.openPending.value : false;
  }

  convertDate(value: any) {
    if (value instanceof Date) {
      return value;
    }
    if (!value._i['year'] && value._i.getTime() === value._d.getTime()) {
      return value.toDate();
    }
    return UtilsService.dateFromServer(value.toDate());
  }

  filterSearchChains(): string {
    // nothing is selected
    var isSelected = this.formSearch.controls.chain.value
    if (!this.chainRestricted && !(isSelected?.length > 0))
      return null
    else if (this.chainRestricted && !(isSelected?.length > 0))
      return this.chains.map(chain => chain.chainId).join(",")

    // get selected chains from selected chain groups
    var selectedChainGrps: ChainGroupModel[] = this.formSearch.controls.chain.value?.map(
      chainGrpId => this.chainGroups.find(chainGrp => chainGrp.groupId === chainGrpId));
    var selectedChains: string[] = [];
    selectedChainGrps.forEach(chainGrp => selectedChains = selectedChains.concat(chainGrp.chains));

    if (!this.chainRestricted)
      return selectedChains.join(",")

    // filter chains
    var chainIds = this.chains.map(chain => chain.chainId);
    var filteredChainIds: string[] = selectedChains.filter(chainId => chainIds.includes(chainId));
    return filteredChainIds.join(",")
  }

  getActivities(page: number, size: number) {
    this.activities = [];
    this.initRestrictedFilters();

    this.activityComplianceService.getActivities(page, size, this.complianceFormData).subscribe(
      response => {
        this.activities = response.content;
        this.totalRows = response.totalElements;

        this.initializeResponses();
        if (!this.checkForMatch(this.activities, 'actGrp', this.activities[0].actGrp)) {
          this.complianceFormData.actGrp = this.activities[0].actGrp;
          this.getResponses(this.activities[0].actGrp);
        } else {
          this.complianceFormData.actGrp = null;
        }
        this.searchPressed = false;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgWarning(this.messageList, error.error.message);
          this.snackBar.open('No records found.', 'Close', {
            duration: 10 * 1000
          });
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;

        this.activities = [];
        this.searchPressed = false;
      }
    );
  }

  initRestrictedFilters() {
    let isPersonalActivity = this.formSearch.controls.activityType.value?.actTypeId === ActivityTypes.PERSONAL.valueOf();
    this.complianceFormData.chain = isPersonalActivity === true ? null : this.filterSearchChains();

    if (this.chainRestricted && !isPersonalActivity &&
        (!this.formSearch.controls.chain.value || this.formSearch.controls.chain.value?.length === 0)) {
      this.formSearch.controls.chain.setValue(this.chainGroups.map(c => c.groupId));
      this.complianceFormData.chainGrpId = this.formSearch.controls.chain.value;
    }

    if (this.terrRestricted && !isPersonalActivity &&
        (!this.formSearch.controls.territory.value || this.formSearch.controls.territory.value?.length === 0)) {
      this.formSearch.controls.territory.setValue(this.terrs);
      this.complianceFormData.terr = this.formSearch.controls.territory.value;
    }

    if (this.complianceFormData.terr === null || this.complianceFormData.terr?.length === 0) {
      this.complianceFormData.terr = null;
    }
  }

  getData() {
    if (this.paginator) {
      this.getActivities(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.getActivities(0, 10);
    }
  }

  ngOnDestroy() {
    if (this.childSubscription) {
      this.childSubscription.unsubscribe();
    }
  }

  onSummary(): void {
    const dialogRef = this.dialog.open(MetricsDialogComponent, {
      data: this.complianceFormData,
    });
  }

  onExport(): void {
    this.isLoading = true;
    this.showNotification = false;
    this.activityComplianceService.getAllActivities(this.complianceFormData, false).subscribe(
      response => {
        this.export(response);
        this.isLoading = false;
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
        this.isLoading = false;
      }
    );
  }

  export(exportInfoData: Export[]) {
    const exportData: any[] = [];
    exportInfoData.forEach(activity => {
      const response = activity.resp ? activity.resps.find(resp => resp.respId === activity.resp) : null;
      const storeCity = activity.custCity ? activity.custCity : ''
      const storeZip = activity.custZip ? activity.custZip : ''
      exportData.push({
        title: activity.title,
        chainNam: activity.chainNam ? activity.chainNam : '',
        account: activity.account,
        str: activity.str,
        strOwnRef: activity.strOwnRef,
        terr: activity.terr,
        stAdr: activity.stAdr ? activity.stAdr + (storeCity ? ', ' + storeCity : '') : (storeCity ? storeCity : ''),
        stZip: storeZip ? storeZip : '',
        imgs: activity.imgs,
        sta: activity.sta === ActivityStatusEnum.COMPLETE ? 'Completed'
          : activity.sta === ActivityStatusEnum.PENDING ? 'Pending' : 'Open',
        updatedOn: activity.updated ? this.getUpdatedDate(activity.updated) : '',
        resp: response ? response.desc : '',
        cmnt: activity.cmnt,
        usrNam: activity.usrNam ? activity.usrNam : activity.updatedBy,
        usrId: activity.usrId ? activity.usrId : '',
        phoneNbr: activity.phoneNbr ? activity.phoneNbr : '',
        city: activity.city ? activity.city : '',
        zip: activity.zip ? activity.zip : '',
        usrTerr: activity.usrTerr ? activity.usrTerr : '',
        usrStAdr: activity.usrStAdr ? activity.usrStAdr : ''
      });
    });

    this.removeEmptyFields(exportData);
    this.excelService.exportExcel(exportData, 'ukg-compliance');
  }

  // remove properties that do not have any value for all Export objects
  removeEmptyFields(exportData: Export[]) {
    if (exportData.length <= 0)
      return

    var skipProperties = ["", "title", "imgs", "sta", "resp", "cmnt", "usrNam"];

    for (const property in exportData[0]) {
      if (skipProperties.includes(property))
        continue

      var emptyColumn = exportData.every(activity => {
        return activity[property] === undefined || activity[property] === null || activity[property] == ''
      });
      emptyColumn ? exportData.map(activity => {delete activity[property]}) : null;
    }
  }

  onDownload(): void {
    const images: ImageZip[] = [];

    Promise.all(this.getSignedUrls()).then(() => {
      this.selection.selected.forEach((activity) => {
        if (activity.imgs) {
          activity.imgs.forEach((image) => {
            images.push({
              url: this.cloudFrontService.getUrlFromStorage(environment.imagesBucket).replace('*', image),
              chain: activity.chain,
              str: activity.str,
              eid: image.substring(0, image.lastIndexOf('_')),
              date: this.datePipe.transform(
                UtilsService.dateFromServer(new Date(Number(image.substring(image.lastIndexOf('_') + 1, image.lastIndexOf('.'))) * 1000)),
                'yyyyMMddHHmmss'
              )
            });
          });
        }
      });

      this.photoDownloaderService.downloadToZip(images, this);
    });
  }

  refreshSelection(event) {
    if (event === true) {
      Promise.all(this.getSignedUrls()).then(() => {
        this.getData();
      });
    }
  }

  getComplianceInd(response: string) {
    const resp = this.fullResponses.find(r => r.desc === response);
    return resp ? resp.complInd : null;
  }

  displayError(event: string) {
    this.msgBanner.addMsgError(this.messageList, event);
    this.showNotification = true;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.activities.length;

    return numSelected >= numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.activities.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ActivityCompliance): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  onExportItemsAndPhotos() {
    this.showNotification = false;
    this.activityComplianceService
      .exportItemsAndPhotos(this.paginator.pageIndex, this.paginator.pageSize, this.complianceFormData).subscribe(
      response => {
        this.dialog.open(ConfirmationDialogComponent, {
          data: new Dialog(response.response,
            false, false, false),
          disableClose: true
        });
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  getUpdatedDate(updated: Date) {
    return this.datePipe.transform(UtilsService.dateFromServer(updated), 'dd/MM/yyyy');
  }

  getActivityType(actType: number): string {
    if (actType === ActivityTypes.SHIPPER_COUNT) {
      return environment.shipperName;
    }
    return this.activityTypes.find((a) => a.actTypeId === actType)?.en;
  }

  changeToCompliance() {
    const chain = this.formSearch.controls.chain.value;
    const type = this.formSearch.controls.activityType.value;
    if (chain && chain.length > 0 && type?.actTypeId !== ActivityTypes.SHIPPER_COUNT.valueOf()) {
      const compliance = this.activityTypes.find((a) => a.actTypeId === ActivityTypes.COMPLIANCE.valueOf());
      this.formSearch.controls.activityType.setValue(compliance);
    }

    if (chain && chain.length === 0) {
      this.formSearch.controls.activityType.setValue(null);
    }

    this.formSearch.controls.activityType.updateValueAndValidity();
  }

  onChange(event: MatSelectChange) {
    if (event.value?.length === 0) {
      this.formSearch.controls.activityStatus.setValue(null);
    }
  }

  toggleStatusSelectAll(selectAllValue: boolean) {
    this.filteredStaMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.formSearch.controls.activityStatus.patchValue(val);
        } else {
          this.formSearch.controls.activityStatus.patchValue([]);
        }
      });
  }

  private initActivityStatuses() {
    this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId));

    this.staMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStaMulti();
      });
  }

  protected filterStaMulti() {
    if (!this.activityStatuses) {
      return;
    }
    // get the search keyword
    let search = this.staMultiFilterCtrl.value;
    if (!search) {
      this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredStaMulti.next(
      this.activityStatuses
        .filter(
          (sta) =>
            sta.en.toLowerCase().indexOf(search) > -1
        ).map(sta => sta.actStaId)
    );
  }

  getActivityStatusById(staId: number) {
    return this.activityStatuses.find((sta: ActivityStatus) => sta.actStaId === staId)?.en;
  }

  getSeason(seasId: number) {
    if (seasId) {
      return this.seasons.find((a) => a.seasId === seasId)?.en;
    }
    return '';
  }

  valueMapper(user: User): string {
    return user?.nam;
  }

  onDropDownOpened(event) {
    if (this.titleSubscribe) {
      this.titleSubscribe.unsubscribe();
    }
    this.titles = [];
    this.initTitleAutocomplete();
    this.formSearch.controls.name.setValue(null);

    this.titleSubscribe = this.getTitles();
  }
}
