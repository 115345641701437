import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivityType} from '../../../../shared/models/activity/activity-type.model';
import {Season} from '../../../../shared/models/season.model';
import {Location} from '../../../../shared/models/location.model';
import {Response} from '../../../../shared/models/activity/response.model';
import {ActivityCompliance} from '../../../../shared/models/activity/activity-compliance.model';
import {PhotoDialogComponent} from '../photo-dialog/photo-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UtilsService} from '../../../../core/services/utils.service';
import {AG_ROLE} from '../../../../shared/constants/roles';
import {ComplianceDialogComponent} from '../compliance-dialog/compliance-dialog.component';
import {ActivityStatusEnum} from '../../../../shared/models/activity/activity-status.model';
import {ActivityConstants} from '../../../../shared/constants/ActivityConstants';
import {CloudfrontService} from '../../../../core/services/cloudfront.service';
import {environment} from '../../../../../environments/environment';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../../shared/models/dialog';
import {ActivityComplianceService} from '../../../../core/services/activity-compliance.service';
import {AttachmentView} from '../../../../shared/models/attachment.config';
import {AttachmentService} from '../../../../core/services/attachment.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivityTypes} from '../../../../shared/constants/ActivityTypes';

export class Details {
  icon: string;
  value: string | number;
  delim: boolean;
}

@Component({
  selector: 'app-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrls: ['./activity-view.component.scss']
})
export class ActivityViewComponent implements OnInit {

  @Input() activity: ActivityCompliance;
  @Input() count: number;
  @Input() activityTypes: ActivityType[];
  @Input() seasons: Season[];
  @Input() locations: Location[];
  @Input() selection;
  @Input() index;
  @Output() refreshEmitter: EventEmitter<any> = new EventEmitter();
  @Output() errorEmitter: EventEmitter<any> = new EventEmitter();

  selectedResponse: Response;
  activityDetails: Details[] = [];

  currentImageIndex = 0;

  nonCompliantResponsesList: Response[] = [];
  compliantResponsesList: Response[] = [];
  updateResponsesQueue: Response[] = [];
  errorMessageOnAddResponse: string;
  errorMessageOnDelete: string;

  isSelected = false;

  constructor(
    private dialog: MatDialog,
    private attachmentService: AttachmentService,
    private cloudFrontService: CloudfrontService,
    private complianceService: ActivityComplianceService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.getSelectedResponse();
    if (this.selectedResponse) {
      this.activity.isCompliant = this.selectedResponse.complInd;
    }
    if (this.activity.imgs && this.activity.imgs.length > 0) {
      this.currentImageIndex = 0;
      // this.refreshTakenOnDate();
    }
    this.activity.isLoadingPicture = this.activity.imgs && this.activity.imgs.length > 0;
    this.computeDetails();
  }

  getSignedUrls(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];

    if (!CloudfrontService.checkIfExistsOrExpired(environment.resizedImagesBucket)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.cloudFrontService.getSignedUrl(environment.resizedImagesBucket).subscribe(
            result => {
              release(true);
            }, error => {
              let message = '';
              if (error.status === 500) {
                message = error.error.message;
              } else {
                message = 'An error has occurred. Please contact your administrator!';
              }

              this.errorEmitter.emit(message);
              reject(error);
            }
          );
        })
      );
    }

    if (!CloudfrontService.checkIfExistsOrExpired(environment.imagesBucket)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.cloudFrontService.getSignedUrl(environment.imagesBucket).subscribe(
            result => {
              release(true);
            }, error => {
              let message = '';
              if (error.status === 500) {
                message = error.error.message;
              } else {
                message = 'An error has occurred. Please contact your administrator!';
              }

              this.errorEmitter.emit(message);
              reject(error);
            }
          );
        })
      );
    }

    return promiseList;
  }

  getSelectedResponse(): void {
    this.activity.resps.forEach((response) => {
      if (response.respId === this.activity.resp) {
        this.selectedResponse = response;
      }
    });
  }

  openPhotoDialog(activity: ActivityCompliance) {
    if (!this.activity.imgs || this.activity.imgs?.length === 0) {
      return;
    }
    Promise.all(this.getSignedUrls()).then(() => {
      const dialogRef = this.dialog.open(PhotoDialogComponent, {
        data: activity,
      });
    });
  }

  getFullImage(image: string) {
    const url = localStorage.getItem(environment.resizedImagesBucket);
    if (url) {
      return JSON.parse(url).url.replace('*', image);
    }
  }

  onLoadFinish() {
    setTimeout(() => {
      this.activity.isLoadingPicture = false;
      },
      500
    );
  }

  computeStoreAddress(): string {
    var custAddress = this.activity.custStAdr ? this.activity.custStAdr : '';
    custAddress += custAddress.length > 0 ? ', ' : '';
    custAddress += this.activity.custCity ? this.activity.custCity : '';
    custAddress += custAddress.length > 0 ? ', ' : '';
    custAddress += this.activity.custZip ? this.activity.custZip : '';

    return custAddress;
  }

  private computeDetails() {
    var custAddress = this.computeStoreAddress();

    const data: Details[] = [
      {
        icon: 'storefront',
        value: this.activity.chain && this.activity.str ? this.activity.chain + '-' + this.activity.str : null,
        delim: true
      },
      {
        icon: 'local_offer',
        value: this.activity.strOwnRef,
        delim: true
      },
      {
        icon: 'place',
        value: custAddress.length > 0 ? custAddress : null,
        delim: true
      },
      {
        icon: 'flag',
        value: this.activity.terr ? this.activity.terr : null,
        delim: true
      },
      {
        icon: 'person',
        value: this.activity.usrNam && this.activity.usrId ? this.activity.usrNam : this.activity.updatedBy,
        delim: true
      },
      {
        icon: 'date_range',
        value: this.datePipe.transform(UtilsService.dateFromServer(this.activity.start), 'dd/MM/yyyy') + ' - ' +
          this.datePipe.transform(UtilsService.dateFromServer(this.activity.end), 'dd/MM/yyyy') + ' (exp ' +
          this.datePipe.transform(UtilsService.dateFromServer(this.activity.exp), 'dd/MM/yyyy') + ')',
        delim: true
      },
      {
        icon: 'camera_alt',
        value: this.activity.imgs && this.activity.imgs.length > 0 ? this.activity.imgs.length : '0',
        delim: true,
      },
      {
        icon: 'assignment',
        value: this.activity.actId,
        delim: true
      }
    ];

    data.forEach(d => {
      if (d.icon === 'camera_alt') {
        if (this.displayExpired()) {
          this.activityDetails.push({
            icon: 'expired',
            value: 'This activity is expired',
            delim: true
          });
        }
      }
      this.activityDetails.push(d);
    });
  }

  getActivityType(actType: number): string {
    if (actType === ActivityTypes.SHIPPER_COUNT) {
      return environment.shipperName;
    }

    return this.activityTypes.find((a) => a.actTypeId === actType)?.en;
  }

  getActivityStatus(sta: number): string {
    return ActivityConstants.ACTIVITY_STATUS.find((a) => a.id === sta)?.name;
  }

  getSeason(seasId: number) {
    if (seasId) {
      return this.seasons.find((a) => a.seasId === seasId)?.en;
    }
    return '';
  }

  getLocation(locId: number) {
    if (locId) {
      return this.locations.find((a) => a.locId === locId)?.en;
    }
    return '';
  }

  getUpdatedDate(updated: Date) {
    return this.datePipe.transform(UtilsService.dateFromServer(updated), 'dd/MM/yyyy');
  }

  getTakenOnDate() {
    return this.datePipe.transform(UtilsService.dateFromServer(
      new Date(Number(this.activity.imgs[this.currentImageIndex].split('_')[1].substring(0, 10)) * 1000)
    ), 'dd/MM/yyyy');
  }

  buildGuardParamBasedRole() {
    const customRoles = [
      AG_ROLE.UK_MERCH_ADMIN,
      AG_ROLE.UK_TERR_MANAGER,
      AG_ROLE.UK_CUST_OPS,
      AG_ROLE.JS_MERCH_ADMIN,
      AG_ROLE.JS_MERCHANDISER_MANAGERS
    ];
    return {
      customeRole: customRoles,
      path: ''
    };
  }

  reinitializeData() {
    this.nonCompliantResponsesList = [];
    this.compliantResponsesList = [];
    this.updateResponsesQueue = [];
    this.reinitializeErrorMessages();
  }

  reinitializeErrorMessages() {
    this.errorMessageOnDelete = '';
    this.errorMessageOnAddResponse = '';
  }

  getCompliantOrNonCompliantResponses(
    complianceFlgOption,
    responsesList: Response[]
  ) {
    const allResponses = this.activity.resps;

    const filteredResponses = allResponses
      .filter((response) => response.complInd === complianceFlgOption)
      .map((response) => {
        if (response.respId === this.activity.resp) {
          response.isSelected = true;
        } else {
          response.isSelected = false;
        }
        return response;
      });

    filteredResponses.forEach((response) => {
      responsesList.push(response);
    });
  }

  openComplianceDialog(responses: Response[], compliance: string) {
    if (!this.checkIfAvailable()) {
      const textToConfirm = this.activity.actType !== ActivityTypes.PHYSICAL_STOCK_COUNT.valueOf() ?
        'You can not mark an activity pending or complete more than 5 days before the start date.' :
        'You can not mark an activity pending or complete before the start date.';
      this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog(textToConfirm, false, false, false),
        disableClose: true,
      });
      return;
    }

    if (responses.length === 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('This compliance has no responses to be selected.', false, false, false),
        disableClose: true,
      });
      return;
    }

    let dialogRef = this.dialog.open(ComplianceDialogComponent, {
      data: {
        compliance: compliance,
        responses: responses,
        taskId: this.activity.id,
        updateResponsesQueue: this.updateResponsesQueue,
        photos: this.activity.imgs?.length,
        comment: this.activity.cmnt,
        shprCt: this.activity.shprCt,
        actType: this.activity.actType,
        actualShprCt: this.activity.actualShprCt,
        end: this.activity.end
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse.event === 'yes') {
        this.refreshEmitter.emit(true);
      }
      dialogRef = null;
    });
  }

  exists(actualShprCt: number) {
    if (Number.isInteger(actualShprCt)) {
      return true;
    } else {
      return false;
    }
  }

  get activityStatus() {
    return ActivityStatusEnum;
  }

  displayExpired() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const expDate = UtilsService.dateFromServer(this.activity.exp);
    if (expDate < currentDate) {
      return true;
    }
    return false;
  }

  previous() {
    this.activity.isLoadingPicture = true;
    Promise.all(this.getSignedUrls()).then(() => {
      this.currentImageIndex = this.currentImageIndex - 1;
      this.getTakenOnDate();
    });
  }

  next() {
    this.activity.isLoadingPicture = true;
    Promise.all(this.getSignedUrls()).then(() => {
      this.currentImageIndex = this.currentImageIndex + 1;
      this.getTakenOnDate();
    });
  }

  checkIfAvailable() {
    const time = UtilsService.dateFromServer(this.activity.start).getTime() - new Date().getTime();
    const dayUntilToday = time / (1000 * 3600 * 24);
    if ((this.activity.actType === ActivityTypes.PHYSICAL_STOCK_COUNT.valueOf() && dayUntilToday < 1) || dayUntilToday > 5) {
      return false;
    }
    return true;
  }

  moveToOpen() {
    if (this.isOpen()) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog('Changing the status to Open will clear selected responses, photos and comments. Are you sure you want to reset the status to Open?',
        true, false, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse) {
        this.changeToOpen();
      }
    });
  }

  changeToOpen() {
    this.complianceService.moveToOpen(this.activity.actId).subscribe(
      response => {
        this.refreshEmitter.emit(true);
      }, error => {
        this.errorEmitter.emit('An error has occurred. Please contact your administrator!');
      }
    );
  }

  downloadFirstAttach() {
    if (this.activity.attach && this.activity.attach.length > 0) {
      if (this.activity.attach[0].startsWith('http')) {
        window.open(this.activity.attach[0]);
      } else {
        this.downloadFile(this.activity.attach[0]);
      }
    }
  }

  downloadFile(attach: string) {
    this.attachmentService.downloadFile(attach, AttachmentView.ACTIVITY.valueOf()).subscribe(
      response => {
        this.attachmentService.download(response);
      }, error => {
        let errorText = '';
        if (error.status === 400) {
          errorText = error.error.message;
        } else if (error.status === 404) {
          errorText = 'File not found. Please contact your administrator.';
        } else {
          errorText = 'An error has occurred. Please contact your administrator.';
        }
        this.snackBar.open(errorText, 'Close', {
          duration: 10 * 1000
        });
      }
    );
  }

  /** Keep the state of the checkbox when page changed */
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    return !!found;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ActivityCompliance): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected >= this.count;
  }

  get getEnvironment() {
    return environment;
  }

  isOpen() {
    return this.activity.sta === ActivityStatusEnum.OPEN.valueOf();
  }
}
