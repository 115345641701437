import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-stock-count-date',
  templateUrl: './edit-stock-count-date.component.html',
  styleUrls: ['./edit-stock-count-date.component.scss']
})
export class EditStockCountDateComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditStockCountDateComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      stockCountDate: [ this.data, [Validators.required] ],
    });
  }

  close() {
    this.dialogRef.close(null);
  }

  save() {
    const date = new Date(this.form.controls.stockCountDate.value);
    this.dialogRef.close(date);
  }

  today() {
    return new Date();
  }
}
