<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >
      {{ data.compliance == 'Y' ? 'Compliant Responses' : 'Non Compliant Responses' }}
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="spinner"
  >
    <a>{{ loadingMessage }}</a>
    <mat-spinner></mat-spinner>
  </div>

  <mat-dialog-content
    *ngIf="!isLoading"
  >
    <form
      fxLayout="column"
      fxLayoutGap="20px"
      [formGroup]="responseForm"
    >

      <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                      (msgListChange)="messageList = $event"></app-msg-banner>

      <div
        fxLayout="column"
        fxLayoutGap="10px"
        class="scrollable fixed-table"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          *ngIf="dataSource && dataSource.data.length"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell class="w-200" *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="!row.isEditActive">
                {{ row.desc }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="imageReq">
            <th mat-header-cell class="w-75" *matHeaderCellDef>Image Required</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.imgReq === true">check</mat-icon>
              <mat-icon *ngIf="row.imgReq === false">close</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="commentReq">
            <th mat-header-cell class="w-75" *matHeaderCellDef>Comment Required</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.cmntReq=== true">check</mat-icon>
              <mat-icon *ngIf="row.cmntReq === false">close</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="preEndDate">
            <th mat-header-cell class="w-75" *matHeaderCellDef>Pre-end date response allowed</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.preEndDate=== true">check</mat-icon>
              <mat-icon *ngIf="row.preEndDate === null || row.preEndDate === false">close</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="rowSelected(row)"
            [ngClass]="{
              hovered: row.hovered,
              highlighted: selection.isSelected(row)
            }"
            (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false"
          >
          </tr>
        </table>
      </div>

      <mat-form-field
        *ngIf="selectedRow?.cmntReq === true"
        fxFlex
        fxFlex.lt-md="100%"
        appearance="standard"
      >
        <mat-label>{{ selectedRow.desc }}</mat-label>
        <input
          matInput
          formControlName="comment"
          [required]="selectedRow.cmntReq === true"
        />
        <mat-error *ngIf="responseForm.controls.comment.errors?.required && responseForm.controls.comment.dirty">
          Comment is required!</mat-error>
        <mat-error *ngIf="responseForm.controls.comment.errors?.pattern && responseForm.controls.comment.dirty">
          * Input not allowed
        </mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="data.actType === getShipperValue()"
        fxFlex
        fxFlex.lt-md="100%"
        appearance="standard"
      >
        <mat-label>Actual {{ getEnvironment.shipperName }}</mat-label>
        <input
          matInput
          type="number"
          [disabled]="data.compliance === 'N'"
          [max]="data.shprCt"
          formControlName="actualShprCt"
          [required]="data.actType === getShipperValue()"
        />
        <mat-hint *ngIf="data.compliance === 'Y'">Enter a value between 1 and {{ data.shprCt }}</mat-hint>
        <mat-error *ngIf="responseForm.controls.actualShprCt.hasError('required')">Actual {{ getEnvironment.shipperName }} is required!
        </mat-error>
        <mat-error
          *ngIf="responseForm.controls.actualShprCt.hasError('max') ||
            responseForm.controls.actualShprCt.hasError('min')"
        >
          Enter a value between 1 and {{ data.shprCt }}!
        </mat-error>
      </mat-form-field>

      <div
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <div
          *ngIf="!responseForm.controls.checked.value || this.data.photos === 0 ||
                  (responseForm.controls.checked.value === true && this.data.photos < 5)"
        >
          <button
            *ngIf="selectedRow?.imgReq === true"
            fxFlex="12%;"
            fxFlex.xs="46%"
            mat-button
            [disableRipple]="true"
            (click)="fileInput.click()"
            [ngStyle]="{'color': fileSelected?.length === 0 && this.submit ? 'red' : '#3f51b5' }"
            class="btn-primary activity-btn" type="button"
          >

            <div
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="center center"
            >
              <mat-icon>add_a_photo</mat-icon>
              <span>Attach images</span>
            </div>
            <input #fileInput type="file" multiple accept=".jpg,.png,.jpeg" name="file" id="file"
                   class="file-choose-input" (change)="onFileSelected($event)"/>
          </button>
        </div>

        <div
          fxLayout="row"
          fxLayoutGap="10px"
          class="scrollable"
        >
          <div class="image" *ngFor="let file of fileSelected; let i = index">
            <img
              class="mini-img"
              [src]="imgUrls[i]"
              [matTooltip]="file.name"
            />
            <button
              style="margin: 0 auto; display: flex;"
              mat-button
              (click)="deletePhoto(i)"
            >
              <mat-icon style="color: red">delete</mat-icon>
            </button>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="5px"
          *ngIf="selectedRow?.imgReq === true && (data.photos && data.photos > 0)"
        >
          <mat-checkbox
            formControlName="checked"
            [checked]="data.photos && data.photos > 0"
          >
            <span style="color: #3f51b5; font-weight: bold">Keep existing photos</span>
          </mat-checkbox>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="2%"
      >
        <button
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-button
          type="button"
          (click)="dialogRef.close({ event: 'no' })"
          class="btn btn-cancel"
        >Cancel
        </button>
        <button
          type="submit"
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-button
          class="btn btn-primary btn-action"
          cdkFocusInitial
          (click)="onClick('yes')"
        >Save
        </button>
      </div>
    </form>

  </mat-dialog-content>

</div>
