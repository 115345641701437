import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesRouting} from './pages.routing';
import {HomePageComponent} from './home-page/home-page.component';
import {PagesComponent} from './pages.component';
import {SharedModule} from '../../shared/shared.module';
import {GenericActivityViewComponent} from '../../shared/components/generic-activity-view/generic-activity-view.component';
import {LoadResponsesComponent} from '../../shared/components/load-responses/load-responses.component';
import {PublishActivityComponent} from './activity/publish-activity/publish-activity.component';
import {ManageActivityComponent} from './activity/manage-activity/manage-activity.component';
import {ActivityDetailComponent} from './activity/activity-detail/activity-detail.component';
import {CdkDetailRowDirective} from './activity/activity-detail/cdk-detail-row.directive';
import {LoadStoreComponent} from '../../shared/components/load-store/load-store.component';
import {CKEditorModule} from 'ckeditor4-angular';
import {MsgBannerService} from '../../shared/components/msg-banner/msg-banner.service';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {AdminService} from '../../core/services/admin.service';
import {ChainNotificationViewComponent} from './notification/chain-notification-view/chain-notification-view.component';
import {TerritoryNotificationViewComponent} from './notification/territory-notification-view/territory-notification-view.component';
import {GeneralNotificationViewComponent} from './notification/general-notification-view/general-notification-view.component';
import {ActivityComplianceComponent} from './compliance/activity-compliance/activity-compliance.component';
import {PhotoDialogComponent} from './compliance/photo-dialog/photo-dialog.component';
import {ComplianceDialogComponent} from './compliance/compliance-dialog/compliance-dialog.component';
import {ActivityDetailsComponent} from './compliance/activity-details/activity-details.component';
import {MetricsDialogComponent} from './compliance/metrics-dialog/metrics-dialog.component';
import {ActivityViewComponent} from './compliance/activity-view/activity-view.component';
import {GhostActivityViewComponent} from './compliance/ghost-activity-view/ghost-activity-view.component';
import {MyDatePickerModule} from 'mydatepicker';
import {MyDateRangePickerModule} from 'mydaterangepicker';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ImgViewerModule} from 'ng-picture-viewer';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ResponsePanelComponent} from '../admin/response-panel/response-panel.component';
import {UserDialogComponent} from '../admin/user-dialog/user-dialog.component';
import {UserManagementComponent} from '../admin/user-management/user-management.component';
import {TrainingManagementComponent} from '../admin/training-management/training-management.component';
import {ResponseDialogComponent} from '../admin/response-dialog/response-dialog.component';
import {ManageResponseComponent} from '../admin/manage-response/manage-response.component';
import {TrainingDialogComponent} from '../admin/training-dialog/training-dialog.component';
import {StaticDataManagementComponent} from '../admin/static-data-management/static-data-management.component';
import {StaticDataDialogComponent} from '../admin/static-data-dialog/static-data-dialog.component';
import {FileStructureComponent} from '../admin/file-structure/file-structure.component';
import {ArchiveStructureComponent} from '../admin/archive-structure/archive-structure.component';
import { PublishNotificationComponent } from './notification/publish-notification/publish-notification.component';
import { PublishDialogComponent } from './notification/publish-dialog/publish-dialog.component';
import { NewActivityComponent } from './activity/new-activity/new-activity.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RoomDialogComponent} from '../admin/room-dialog/room-dialog.component';
import {RoomManagementComponent} from '../admin/room-management/room-management.component';
import {DocumentsStructureComponent} from '../admin/documents-structure/documents-structure.component';
import { GeneralDocumentsComponent } from './notification/general-documents/general-documents.component';
import { ChainDocumentsComponent } from './notification/chain-documents/chain-documents.component';
import { ActivityQuickSearchComponent } from './compliance/activity-quick-search/activity-quick-search.component';
import { ManageActivityGroupComponent } from './activity/manage-activity-group/manage-activity-group.component';
import { TerritoryChangesComponent } from '../admin/territory-changes/territory-changes.component';
import { ManageActivityGroupDirective } from './activity/manage-activity-group/manage-activity-group.directive';
import { ResponseGroupTypeComponent } from '../admin/response-group-type/response-group-type.component';
import { ResponseGroupTypeDialogComponent } from '../admin/response-group-type-dialog/response-group-type-dialog.component';

@NgModule({
  declarations: [
    HomePageComponent,
    PagesComponent,
    GenericActivityViewComponent,
    ManageActivityComponent,
    PublishActivityComponent,
    LoadResponsesComponent,
    NewActivityComponent,
    ActivityDetailComponent,
    CdkDetailRowDirective,
    LoadStoreComponent,

    GeneralNotificationViewComponent,
    TerritoryNotificationViewComponent,
    ChainNotificationViewComponent,

    ActivityComplianceComponent,
    MetricsDialogComponent,
    ActivityDetailsComponent,
    ComplianceDialogComponent,
    PhotoDialogComponent,
    ActivityViewComponent,
    GhostActivityViewComponent,

    ManageResponseComponent,
    ResponsePanelComponent,
    ResponseDialogComponent,
    UserManagementComponent,
    UserDialogComponent,
    RoomManagementComponent,
    RoomDialogComponent,
    TrainingManagementComponent,
    TrainingDialogComponent,
    StaticDataManagementComponent,
    StaticDataDialogComponent,
    ResponseGroupTypeComponent,
    ResponseGroupTypeDialogComponent,
    FileStructureComponent,
    ArchiveStructureComponent,
    PublishNotificationComponent,
    PublishDialogComponent,
    DocumentsStructureComponent,
    GeneralDocumentsComponent,
    ChainDocumentsComponent,
    ActivityQuickSearchComponent,
    ManageActivityGroupComponent,
    TerritoryChangesComponent,
    ManageActivityGroupDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    PagesRouting,
    SharedModule,
    CKEditorModule,
    CoreModule,
    MyDatePickerModule,
    MyDateRangePickerModule,
    NgApexchartsModule,
    ModalModule.forRoot(),
    ImgViewerModule,
  ],
  exports: [
    LoadResponsesComponent
  ],
  providers: [
    MsgBannerService,
    MatSnackBar,
    AdminService
  ]
})
export class PagesModule { }
