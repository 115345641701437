<div
  fxLayout="column"
  fxLayoutGap="10px"
>
    <!-- dialog title and close btn -->
    <div
        fxFlex
        fxLayout="row">
        <div
            fxFlex
            fxLayout="column"
            fxLayoutAlign="start">
            <h1 class="title"> Select Customer Account </h1>
        </div>

        <div
            fxFlex
            fxLayoutAlign="end">
            <mat-icon
            (click)="onCancel()"
            class="close-button">close</mat-icon>
        </div>
    </div>

    <form [formGroup]="chainGroupForm">
        <mat-form-field
            fxFlex>
            <mat-label>Customer Account</mat-label>
            <mat-select formControlName="group" required>
                <mat-option *ngFor="let group of chainGroups" [value]="group"> {{ group.desc }} </mat-option>
            </mat-select>
        </mat-form-field>
    </form>


    <!-- button -->
    <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center center"
        fxLayoutAlign.lt-md="start"
        fxLayoutGap="10px"
        fxLayoutGap.lt-md="5px">
        <button mat-button
            fxFlex="100px"
            fxFlex.lt-md="100%"
            class="btn btn-primary btn-action"
            style="background-color: #42a5f5;"
            (click)="onOk()">Ok</button>
        <button mat-button
            fxFlex="100px"
            fxFlex.lt-md="100%"
            class="btn btn-primary btn-action"
            style="background-color: #f44336"
            (click)="onCancel()">Cancel</button>
    </div>

</div>
