import { SelectionModel } from '@angular/cdk/collections';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Response } from '../../../../shared/models/activity/response.model';
import {CustomFieldsValidators} from '../../../../shared/components/custom-validators/CustomFieldsValidators';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {CompliantMessagesService} from '../../../../core/services/compliant-messages.service';
import {ActivityComplianceService} from '../../../../core/services/activity-compliance.service';
import {ActivityTypes} from '../../../../shared/constants/ActivityTypes';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-compliance-dialog',
  templateUrl: './compliance-dialog.component.html',
  styleUrls: ['./compliance-dialog.component.scss'],
})
export class ComplianceDialogComponent implements OnInit, OnDestroy {
  selection: SelectionModel<any>;
  dataSource: MatTableDataSource<Response>;
  doNotSelect: Response;
  selectedRow: Response;
  fileSelected: File[] = [];
  imgUrls: any[] = [];
  @ViewChild('fileInput') attachment: any;

  responseForm: FormGroup;
  displayedColumns = ['name', 'imageReq', 'commentReq', 'preEndDate'];
  duplicateValue: boolean;
  submit = false;

  // error list
  messageList = [];
  showNotification = false;

  loadingMessage = '';
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private msgBanner: MsgBannerService,
    private compliantMessageService: CompliantMessagesService,
    private activityComplianceService: ActivityComplianceService,
    public dialogRef: MatDialogRef<ComplianceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new MatTableDataSource<Response>();
    this.selection = new SelectionModel<any>(false, []);
  }

  ngOnInit(): void {
    this.dataSource.data = this.data.responses;

    this.responseForm = this.fb.group({
      comment: [this.data.comment, [CustomFieldsValidators.maxComment, Validators.required, Validators.pattern(environment.emojiRegEx)]],
      actualShprCt: [ this.data.actualShprCt ],
      uploadPhotos: [this.data.uploadPhotos],
      checked: [this.data.checked]
    });
    this.responseForm.controls.comment.updateValueAndValidity();

    const selectedResponse = this.dataSource.data.filter(
      (response) => response.isSelected === true
    );
    if (selectedResponse.length) {
      this.selection.select(selectedResponse.pop());
      this.selectedRow = this.selection.selected[0];
      if (this.selectedRow.cmntReq === false) {
        this.responseForm.controls.comment.setValidators(null);
        this.responseForm.controls.comment.updateValueAndValidity();
      }
      if (this.selectedRow.imgReq === true) {
        this.responseForm.controls.checked.setValue(true);
        this.responseForm.controls.checked.updateValueAndValidity();
      }
    }
    if (this.data.actType === this.getShipperValue() && this.data.compliance === 'Y') {
      this.responseForm.controls.actualShprCt.setValidators([
        Validators.required, Validators.max(this.data.shprCt), Validators.min(1)
      ]);
    } else if (this.data.actType === this.getShipperValue() && this.data.compliance === 'N') {
      this.responseForm.controls.actualShprCt.setValidators(null);
      this.responseForm.controls.actualShprCt.setValue(0);
      this.responseForm.controls.actualShprCt.disable();
    } else {
      this.responseForm.controls.actualShprCt.setValidators(null);
    }
    this.responseForm.controls.actualShprCt.updateValueAndValidity();
  }

  clearSelection() {
    this.selection.clear();
    this.selectedRow = null;
  }

  rowSelected(row: Response) {
    this.showNotification = false;
    if ((row.preEndDate === false || row.preEndDate === null) && !this.isEndDateEligible()) {
      this.clearSelection();
      this.msgBanner.addMsgError(this.messageList, 'This response cannot be selected until the final day of activity.');
      this.showNotification = true;
      return;
    }

    if (row.cmntReq === false) {
      this.responseForm.controls.comment.setValidators(null);
      this.responseForm.controls.comment.updateValueAndValidity();
    }
    this.selectedRow = row;
    if (row === this.doNotSelect) {
      this.doNotSelect = null;
      return;
    }
    if (row.imgReq) {
      if (this.data.photos) {
        this.responseForm.controls.checked.setValue(true);
      } else {
        this.responseForm.controls.checked.setValue(false);
      }
      this.responseForm.controls.checked.updateValueAndValidity();
    }

    this.selection.clear();
    this.selection.toggle(row);
  }

  dontSelectRow(row: Response) {
    this.doNotSelect = row;
  }

  private getEpoch() {
    return Math.floor(new Date().getTime() / 1000);
  }

  onClick(action) {
    this.submit = true;
    this.showNotification = false;
    this.messageList = [];

    if (this.responseForm.invalid) {
      this.msgBanner.addMsgError(this.messageList, 'Please complete all required fields.');
      this.showNotification = true;
      return;
    }

    if (action === 'yes' && this.selection.isEmpty()) {
      this.msgBanner.addMsgWarning(this.messageList, 'You must select one response!');
      this.showNotification = true;
      return;
    }

    if (this.selection.selected.length) {
      if (this.selectedRow.imgReq === true && this.fileSelected?.length) {
        this.responseForm.controls.uploadPhotos.setValue(true);
      }
      if (this.selectedRow.imgReq === true && this.data.photos && this.fileSelected.length === 0) {
        this.responseForm.controls.uploadPhotos.setValue(false);
      }

      if (this.selectedRow.imgReq === true &&
        (this.fileSelected?.length === 0 || this.fileSelected?.length === undefined) &&
        (this.data.photos === 0 || this.data.photos === undefined)) {
        this.msgBanner.addMsgWarning(this.messageList, 'Please add a photo. You can add maximum ' +
          (5 - (this.data.photos ? this.data.photos.length : 0)) + ' photos!');
        this.showNotification = true;
        return;
      }

      // validate number of photos uploaded + existing if is checked
      const photos = this.data.photos + this.fileSelected?.length;
      if (this.responseForm.controls.checked.value === true && photos > 5) {
        this.msgBanner.addMsgWarning(this.messageList, 'Please add a photo. You can add maximum ' +
          (5 - (this.data.photos ? this.data.photos : 0)) + ' photos!');
        this.showNotification = true;
        return;
      }

      // if unchecked and (images not uploaded if img required)
      if (!this.responseForm.controls.checked.value && this.selectedRow.imgReq === true && this.fileSelected?.length === 0) {
        this.msgBanner.addMsgWarning(this.messageList, 'Please add a photo. You can add maximum ' +
          (5 - (this.data.photos ? this.data.photos : 0)) + ' photos!');
        this.showNotification = true;
        return;
      }

      this.loadingMessage = 'Work in progress...';
      this.isLoading = true;
      this.activityComplianceService
        .updateResponse(this.data.taskId, this.selectedRow.respId,
          this.selectedRow.cmntReq ? this.responseForm.controls.comment.value : null,
          this.data.actType === this.getShipperValue() ? this.responseForm.controls.actualShprCt.value : null,
          this.selectedRow.complInd, this.selectedRow.imgReq ? this.responseForm.controls.uploadPhotos.value : null,
          this.selectedRow.imgReq ? this.responseForm.controls.checked.value : null).subscribe(
        response => {
          if (this.selectedRow.imgReq === true && this.fileSelected?.length) {
            this.loadingMessage = 'Uploading photo(s)...';
            const initialFiles = Array.from(this.fileSelected);
            this.uploadPhotos(action, 0, this.getEpoch(), initialFiles);
          } else {
            this.isLoading = false;
            this.dialogRef.close({
              event: action
            });
          }
        }, error => {
          if (error.status === 404) {
            this.msgBanner.addMsgWarning(this.messageList, error.error.message);
          } else {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
          }
          this.showNotification = true;
          this.isLoading = false;
        }
      );

    } else {
      this.dialogRef.close({ event: 'no' });
    }
  }

  uploadPhotos(action, counter, timestamp, initialFiles: File[]) {
    this.compliantMessageService.uploadPhoto(this.data.taskId, initialFiles[counter], timestamp).subscribe(
      response => {
        if (!response) {
          this.msgBanner.addMsgError(this.messageList, 'Something went wrong with file ' + initialFiles[counter].name + '!');
          this.showNotification = true;
        }

        if (counter === initialFiles.length - 1) {
          this.isLoading = false;
          this.dialogRef.close({
            event: action
          });
        } else {
          counter++;
          timestamp++;
          this.uploadPhotos(action, counter, timestamp, initialFiles);
        }
      }, error => {
        if (error.status === 417 || error.status === 413) {
          this.msgBanner.addMsgError(this.messageList, 'File size exceeded for file: ' + initialFiles[counter]?.name);
          const index: number = this.fileSelected.findIndex(f => f.name === initialFiles[counter].name);
          this.fileSelected.splice(index, 1);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'Something went wrong with file ' + initialFiles[counter]?.name + '!');
        }
        this.showNotification = true;

        if (counter === initialFiles.length - 1) {
          this.isLoading = false;
        } else {
          counter++;
          timestamp++;
          this.uploadPhotos(action, counter, timestamp, initialFiles);
        }
      }
    );
  }

  ngOnDestroy(): void {
    console.log('Destroyed dialog');
    this.dataSource.data = null;
    this.dataSource = null;
  }

  onFileSelected(event) {
    this.showNotification = false;
    this.messageList = [];

    const files = <File[]>event.target.files;
    if (this.responseForm.controls.checked.value === true) {
      if (this.data.photos + this.fileSelected.length + files.length > 5) {
        this.msgBanner.addMsgWarning(this.messageList, 'You can upload maximum ' +
          (5 - (this.data.photos ? this.data.photos : 0)) + ' photos!');
        this.showNotification = true;
        return;
      } else {
        for (const file of files) {
          if ((file.size / 1024 / 1024) > environment.maxFileUploadSize) {
            this.msgBanner.addMsgWarning(this.messageList, 'File size exceeded(' + environment.maxFileUploadSize + 'MB) for ' + file.name);
            this.showNotification = true;
            continue;
          }
          this.fileSelected.push(file);
        }
      }
    }
    if (this.responseForm.controls.checked.value === false) {
      if (this.fileSelected.length + files.length > 5) {
        this.msgBanner.addMsgWarning(this.messageList, 'You can upload maximum 5 photos!');
        this.showNotification = true;
        return;
      } else {
        for (const file of files) {
          if ((file.size / 1024 / 1024) > environment.maxFileUploadSize) {
            this.msgBanner.addMsgWarning(this.messageList, 'File size exceeded(' + environment.maxFileUploadSize + 'MB) for ' + file.name);
            this.showNotification = true;
            continue;
          }
          this.fileSelected.push(file);
        }
      }
    }

    for (let i = 0; i < this.fileSelected.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileSelected[i]);
      reader.onload = (_event) => {
        this.imgUrls[i] = reader.result;
      };
    }

    this.attachment.nativeElement.value = '';
  }

  deletePhoto(index: number) {
    this.fileSelected.splice(index, 1);
    this.imgUrls.splice(index, 1);
  }

  getShipperValue() {
    return ActivityTypes.SHIPPER_COUNT.valueOf();
  }

  closeDialog() {
    this.dialogRef.close({ event: 'no' });
  }

  get getEnvironment() {
    return environment;
  }

  private isEndDateEligible() {
    const dayPrecision = 24 * 60 * 60 * 1000;
    const today = Math.round(new Date().getTime() / dayPrecision);
    const endDate = Math.round(new Date(this.data.end).getTime() / dayPrecision);
    return today >= endDate;
  }
}
