<mat-dialog-content
  [formGroup]="form"
>
  <div
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayoutAlign="start">Amend Stock Count Date</div>
    <div fxLayoutAlign="end">
      <button
        mat-icon-button
        (click)="close()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-form-field>
    <mat-label>Stock Count Date</mat-label>
    <input
      formControlName="stockCountDate"
      matInput
      required
      [matDatepicker]="picker"
      [min]="today()"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
      style="display: inline-block"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      style="display: inline-block"
    ></mat-datepicker>
  </mat-form-field>

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center center"
    fxLayoutAlign.lt-md="start"
    fxLayoutGap="2%"
    fxLayoutGap.lt-md="0"
  >
    <button fxFlex="90px" fxFlex.lt-md="100%" mat-button
            class="btn btn-primary blue-btn"
            [disabled]="this.form.invalid"
            (click)="save()"
    >Save</button>
    <button fxFlex="90px" fxFlex.lt-md="100%" mat-button
            (click)="close()"
            class="btn btn-primary red-btn"
    >Close</button>
  </div>
</mat-dialog-content>
