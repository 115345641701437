export class ResponseGroupType {
  id: number;
  type: string;
  installations: InstallationType[];

  public constructor(init?: Partial<ResponseGroupType>) {
    Object.assign(this, init);
  }
}

export class InstallationType {
  id: number;
  desc: string;

  public constructor(init?: Partial<InstallationType>) {
    Object.assign(this, init);
  }
}
