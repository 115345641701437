<div>

  <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                  (msgListChange)="messageList = $event"></app-msg-banner>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="2%"
  >
    <div
      fxFlex
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <div
        fxFlex.lt-md="100%"
        fxLayoutAlign="start center"
        [ngSwitch]="this.config.view"
      >
        <h1 *ngSwitchCase="attachmentView.ADMIN">
          <span *ngIf="!config.isArchived">MANAGE ATTACHMENTS</span>
          <span *ngIf="config.isArchived">MANAGE ARCHIVES</span>
        </h1>
        <h1 *ngSwitchCase="attachmentView.ADMIN_DOCUMENTS">MANAGE DOCUMENTS</h1>
        <h1 *ngSwitchCase="attachmentView.ROOM_DOCUMENTS">DOCUMENTS</h1>
        <h1 *ngSwitchCase="attachmentView.VIEW_GENERAL_DOCUMENTS">GENERAL DOCUMENTS</h1>
        <h1 *ngSwitchCase="attachmentView.VIEW_CHAIN_DOCUMENTS">ACCOUNT DOCUMENTS</h1>
        <h1 *ngSwitchCase="attachmentView.ACTIVITY">ATTACH FILE</h1>
        <h1 *ngSwitchCase="attachmentView.NOTIFICATION">ATTACH FILE</h1>
        <h1 *ngSwitchCase="attachmentView.NOTIFICATION_TERRITORY">ATTACH FILE</h1>
      </div>

      <mat-form-field
        fxFlex="70%"
        fxFlex.lt-md="100%"
        fxFlexAlign="start"
      >
        <input
          matInput
          placeholder="Search file"
          [(ngModel)]="searchValue"
          (keydown.enter)="applyFilter()"
        >
        <button
          mat-button
          matSuffix
          mat-stroked-button
          class="search-button"
          (click)="applyFilter()"
          aria-label="search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <button
      mat-icon-button
      *ngIf="config.view !== attachmentView.ADMIN && config.view !== attachmentView.ADMIN_DOCUMENTS
              && config.view !== attachmentView.VIEW_GENERAL_DOCUMENTS && config.view !== attachmentView.VIEW_CHAIN_DOCUMENTS"
      fxFlexAlign="end start"
    >
      <mat-icon
        (click)="closeDialog()"
      >close</mat-icon>
    </button>
  </div>

  <div
    *ngIf="isLoadingTree && config.view !== attachmentView.ADMIN && config.view !== attachmentView.ADMIN_DOCUMENTS
            && config.view !== attachmentView.VIEW_GENERAL_DOCUMENTS && config.view !== attachmentView.VIEW_CHAIN_DOCUMENTS"
    class="spinner-container"
  >
    <span>{{ loadingMessage }}</span>
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="false">
    <button
      mat-stroked-button
      class="addNewFolder"
      (click)="addNewItem(null)"
    >
      <mat-icon>add</mat-icon>
      ADD NEW MASTER FOLDER
    </button>
  </div>

  <div
    [ngClass]="{
        'scroll-tree': config.view !== attachmentView.ADMIN && config.view !== attachmentView.ADMIN_DOCUMENTS
                        && config.view !== attachmentView.VIEW_GENERAL_DOCUMENTS && config.view !== attachmentView.VIEW_CHAIN_DOCUMENTS,
        'scroll-horizontal':  config.view === attachmentView.ADMIN || config.view === attachmentView.ADMIN_DOCUMENTS
                                || config.view === attachmentView.VIEW_GENERAL_DOCUMENTS || config.view === attachmentView.VIEW_CHAIN_DOCUMENTS
    }"
  >
    <mat-tree *ngIf="!isLoadingTree" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
      >
        <button mat-icon-button disabled></button>

        <mat-checkbox *ngIf="isDocumentBulkDeleteEnabled && node.item?.type === 'file'"
                      class="document-checkbox"
                      (change)="$event ? selection.toggle(node) : null"
                      [disabled]="isSelectionSizeExceeded(node)">
        </mat-checkbox>

        <mat-checkbox *ngIf="isPopupOpened() && node.item?.type === 'file'"
                      class="document-checkbox"
                      (change)="$event ? attachMultipleSelection.toggle(node) : null"
                      [disabled]="isAttachMultipleSelectionSizeExceeded(node)">
        </mat-checkbox>

        <mat-icon
          *ngIf="node.item?.type === 'file' && node.item?.isNew"
          class="file-icon"
          matBadge="1"
          matBadgeSize="medium"
          matBadgePosition="before"
        >insert_drive_file
        </mat-icon>
        <mat-icon
          *ngIf="node.item?.type === 'file' && !node.item?.isNew"
          class="file-icon"
        >insert_drive_file
        </mat-icon>

        <mat-form-field [formGroup]="renameForm" *ngIf="node.editable">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="node.item?.type === 'file' &&
                            renameForm.controls.name.errors?.pattern &&
                            renameForm.controls.name.dirty">
            File name must have the .pdf extension
          </mat-error>

        </mat-form-field>
        <span
          *ngIf="!node.editable"
          class="file"
          matTooltip="Last modified on: {{ node?.item.lastModifiedDate | dateFormat }}
                    Last modified by: {{ node?.item.lastModifiedBy }}
                    File size: {{ formatBytes(node.item?.fileSize) }}"
          matTooltipClass="material-tooltip"
          (click)="downloadFile(node)"
        >{{ node.item?.name }}</span>

        <button mat-icon-button *ngIf="node.editable" (click)="rename(node)">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button *ngIf="node.editable" (click)="cancelRename(node)">
          <mat-icon>close</mat-icon>
        </button>

        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          *ngIf="!node.editable && !config.isArchived
                  && (config.view !== attachmentView.VIEW_CHAIN_DOCUMENTS && config.view !== attachmentView.VIEW_GENERAL_DOCUMENTS)"
        >
          <mat-icon class="more-button">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editFileName(node)" *ngIf="node.item?.type === 'file'">
            <mat-icon class="more-button">edit</mat-icon>
            <span>Rename File</span>
          </button>
          <button mat-menu-item (click)="deleteObjects(node)" *ngIf="node.item?.type === 'file'">
            <mat-icon class="more-button">delete</mat-icon>
            <span>Delete File</span>
          </button>
        </mat-menu>
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasNoContent"
        matTreeNodePadding
      >
        <button mat-icon-button disabled></button>
        <mat-form-field>
          <mat-label>New folder...</mat-label>
          <input matInput #itemValue placeholder="Folder name"/>
        </mat-form-field>
        <button mat-icon-button (click)="saveNode(node, itemValue.value)">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteNode(node)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button
          mat-icon-button
          matTreeNodeToggle
          [disabled]="node.item.type === 'folder' && !hasChildren(node) && node.loaded"
          (click)="getFolderContent(node)"
          [attr.aria-label]="'Toggle ' + node.item?.name"
        >
          <mat-icon
            class="mat-icon-rtl-mirror"
            [ngStyle]="{'color': node.item.type === 'folder' && !hasChildren(node) && node.loaded ? 'white' : ''}"
          >
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>

        <div
          matTreeNodeToggle
          matRipple
          class="folder"
          (click)="getFolderContent(node);"
        >
          <mat-icon *ngIf="node.item?.type === 'folder'" class="file-icon">folder_open</mat-icon>

          <mat-form-field [formGroup]="renameForm" *ngIf="node.editable">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <span *ngIf="!node.editable">{{ node.item?.name }}</span>
        </div>

        <button mat-icon-button *ngIf="node.editable" (click)="rename(node)">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button *ngIf="node.editable" (click)="cancelRename(node)">
          <mat-icon>close</mat-icon>
        </button>

        <button mat-icon-button
                *ngIf="node.item?.type === 'folder' && !node.editable && !node.item?.isRoot && config.view === attachmentView.ROOM_DOCUMENTS"
                (click)="linkFile(node)"
        >
          <mat-icon
            matTooltip="Reference Document Folder to room"
            class="link-button">link
          </mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="uploadFile(node)"
          *ngIf="node.item?.type === 'folder' && !node.editable && !node.item?.isRoot && !config.isArchived
                  && (config.view !== attachmentView.VIEW_CHAIN_DOCUMENTS && config.view !== attachmentView.VIEW_GENERAL_DOCUMENTS && config.view !== attachmentView.ROOM_DOCUMENTS)"
        >
          <mat-icon matTooltip="Upload" class="link-button">upload</mat-icon>
        </button>

        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          *ngIf="!node.editable && !config.isArchived
                   && (config.view === attachmentView.ADMIN || config.view === attachmentView.ADMIN_DOCUMENTS || config.view === attachmentView.ROOM_DOCUMENTS)"
        >
          <mat-icon class="more-button">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="addNewItem(node)" *ngIf="node.item?.type === 'folder'">
            <mat-icon class="more-button">add</mat-icon>
            <span>Add Subfolder</span>
          </button>
          <button mat-menu-item (click)="editFileName(node)" *ngIf="false">
            <mat-icon class="more-button">edit</mat-icon>
            <span>Rename Folder</span>
          </button>
          <button mat-menu-item (click)="deleteObjects(node)"
                  *ngIf="node.item?.type === 'folder' && !node.item?.isRoot">
            <mat-icon class="more-button">delete</mat-icon>
            <span>Delete Folder</span>
          </button>
        </mat-menu>

        <div
          fxFlex
          *ngIf="node.loadingBar && !node.loaded"
          class="loading-bar"
        >
          <mat-progress-bar
            mode="indeterminate"
            class="example-tree-progress-bar"
          ></mat-progress-bar>
        </div>

      </mat-tree-node>
    </mat-tree>
  </div>


  <div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="2%"
  fxLayoutGap.lt-md="0"
>
  <button
      *ngIf="isBulkDelete && !isDocumentBulkDeleteEnabled && !isLoadingTree"
      fxFlex="150px"
      fxFlex.lt-md="100%"
      mat-raised-button
      class="btn-bulk-delete"
      color="primary"
      type="button"
      (click)="enableBulkDelete()"
    > Enable Bulk Delete </button>

  <button
    *ngIf="isBulkDelete && isDocumentBulkDeleteEnabled"
    fxFlex="150px"
    fxFlex.lt-md="100%"
    mat-raised-button
    class="btn-bulk-delete"
    color="primary"
    type="button"
    (click)="onBulkDelete()"
  > Delete Selected </button>

  <button
    *ngIf="isPopupOpened() && !isLoadingTree"
    fxFlex="150px"
    fxFlex.lt-md="100%"
    mat-raised-button
    class="btn-bulk-delete"
    color="primary"
    type="button"
    [disabled]="this.attachMultipleSelection.isEmpty()"
    (click)="linkFiles()"
  > Link Selected Files </button>

  <button
    *ngIf="isBulkDelete && isDocumentBulkDeleteEnabled"
    fxFlex="150px"
    fxFlex.lt-md="100%"
    mat-raised-button
    class="btn-cancel btn-bulk-delete"
    color="primary"
    type="button"
    (click)="disableBulkDelete()"
  > Cancel </button>
</div>

</div>
