import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AdminService } from '../../../core/services/admin.service';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StringResponse } from '../../../shared/models/string-response';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '../../../shared/models/dialog';
import { ResponseGroupType } from '../../../shared/models/activity/response-group-type.model';
import { ResponseGroupTypeDialogComponent } from '../response-group-type-dialog/response-group-type-dialog.component';

@Component({
  selector: 'app-response-group-type',
  templateUrl: './response-group-type.component.html',
  styleUrls: ['./response-group-type.component.scss']
})
export class ResponseGroupTypeComponent implements OnInit, AfterViewInit {

  displayedColumns = [
    'name',
    'actions'
  ];

  // error list
  messageList = [];
  showNotification = false;

  searchValue: string = null;
  dataSource = new MatTableDataSource<ResponseGroupType>();

  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name': return item.type;
          default: return item[property];
        }
      };
    }
  }

  constructor(private service: AdminService,
              private msgBanner: MsgBannerService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: ResponseGroupType, filter: string) =>
      (data.type)
        .trim()
        .toLowerCase()
        .indexOf(filter) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.getData();
  }

  applyFilter(clearKeyUp?: boolean) {
    if (clearKeyUp && this.searchValue != null && this.searchValue.length === 0) {
      this.getData();
    } else if (clearKeyUp === undefined) {
      this.getData();
    }
  }

  getData() {
    this.showNotification = false;
    this.getResponseGroupType();
  }

  getResponseGroupType() {
    this.service.getResponseGroupType(this.searchValue).subscribe(
      (response: ResponseGroupType[]) => {
        this.dataSource = new MatTableDataSource<ResponseGroupType>(response);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<ResponseGroupType>();

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ResponseGroupTypeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.createResponseGroupType(data);
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  createResponseGroupType(data) {
    this.service.createResponseGroupType(data).subscribe(
      (response: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(response.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  showConfirmationMessageAndRetrieveData(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, false, false, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        this.getData();
        if (response) {
          return;
        }
      }
    );
  }

  openEdit(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(ResponseGroupTypeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          response.seasId = data.seasId;
          this.editResponseGroupType(response);
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  editResponseGroupType(response) {
    this.service.editResponseGroupType(response).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  onDelete(data: ResponseGroupType) {
    const text = 'Do you want to delete this response group type?';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(text, true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          this.deleteResponseGroupType(data);
        }
      }
    );
  }

  deleteResponseGroupType(data) {
    this.service.deleteResponseGroupType(data).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }


}
