<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >{{ action }} {{ application }}
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="2%"
  >
    <form
      [formGroup]="form"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start"
    >
      <mat-form-field
        hintLabel="Max 200 characters"
        appearance="standard"
      >
        <input
          type="text"
          formControlName="type"
          matInput
          #input
          maxlength="200"
          placeholder="Enter Name"
          required
        />
        <mat-hint align="end">{{ input.value?.length || 0 }}/{{ input.maxLength }}</mat-hint>
        <mat-error *ngIf="form.controls.type.errors?.pattern && form.controls.type.dirty">
          * Input not allowed
        </mat-error>
      </mat-form-field>
    </form>

    <button
      class="btn btn-submit"
      (click)="addNewRecord()"
    >Add new installation type</button>

    <table
      #table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z1"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [formGroup]="element"
        >
          <mat-form-field
            appearance="{{ form.get('installations').value[i].isEditable ? 'none' : 'legacy' }}"
          >
            <input
              matInput
              type="text"
              formControlName="desc"
              [readonly]="form.get('installations').value[i].isEditable"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell class="action-col" *matHeaderCellDef>Action</th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [formGroup]="element"
        >
          <button
            mat-icon-button
            class="material-icons app-toolbar-menu save-button"
            matTooltip="Save Changes"
            (click)="saveRecord(i)"
            *ngIf="!form.get('installations').value[i].isEditable"
            color="primary"
          >
            <mat-icon>check_circle</mat-icon>
          </button>

          <button
            mat-icon-button
            class="material-icons app-toolbar-menu cancel-button"
            matTooltip="Cancel Changes"
            color="warn"
            (click)="cancelRecord(i)"
            *ngIf="!form.get('installations').value[i].isEditable"
          >
            <mat-icon>cancel</mat-icon>
          </button>

          <button
            mat-icon-button
            class="material-icons app-toolbar-menu save-button"
            *ngIf="form.get('installations').value[i].isEditable"
            matTooltip="Edit"
            color="primary"
            (click)="editRecord(i)"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <button
            mat-icon-button
            class="material-icons app-toolbar-menu delete-button"
            *ngIf="form.get('installations').value[i].isEditable"
            matTooltip="Delete"
            color="warn"
            (click)="deleteRecord(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button
        id="submit"
        class="btn btn-submit"
        (click)="onSubmit()"
        [disabled]="!form.valid"
        fxFlex="100px"
        fxFlex.lt-md="100%"
      >
        {{ action }}
      </button>
      <button
        id="cancel"
        class="btn btn-cancel"
        (click)="onCancel()"
        fxFlex="100px"
        fxFlex.lt-md="100%"
      >
        Cancel
      </button>
    </div>
  </mat-dialog-content>
</div>
